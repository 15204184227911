import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import axios from '../utils/axios';
import logout from '../utils/logout';
import { Button, TextInput } from 'flowbite-react';
import { MicrophoneIcon } from '@heroicons/react/24/solid';
import ContinueButtonGroup from './continue-bgroup';
import DefaultAlerts from './default-alerts';
import useErrMsg from '../hooks/use-errormsg';
import useOrder from '../hooks/use-order';
import useUser from '../hooks/use-user';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';

//CARE: audio recording only allowed on HTTPS connections or LOCALHOST

export const maxFileSize = 1000 * 1024 * 1024;

export default function FileUpload() {
    const [fileNoteState, setFileNoteState] = useState('');
    const { activeOrder } = useOrder();
    const orderId = activeOrder.orderId;
    const [displayErrMsg, setDisplayErrMsg] = useState(false);
    const [displaySuccessMsg, setDisplaySuccessMsg] = useState(false);
    const { errMsg, setErrMsg, successMsg, stdSuccessMsg, setSuccessMsg, stdErrMsg } = useErrMsg();
    const { auth, setAuth } = useAuth();
    const { setUserState } = useUser();
    const navigate = useNavigate();
    const inputRef = useRef(null);

    const location = useLocation();

    const [audioState, setAudioState] = useState({ isRecording: false });
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setFileNoteState(activeOrder.fileNote ?? '');
    }, [activeOrder]);

    async function recordAudio() {
        return new Promise(async (resolve, reject) => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const mediaRecorder = new MediaRecorder(stream);
                const audioChunks = [];

                mediaRecorder.addEventListener('dataavailable', event => {
                    audioChunks.push(event.data);
                });

                function start() {
                    mediaRecorder.start();
                }

                function stop() {
                    return new Promise(resolve => {
                        mediaRecorder.addEventListener('stop', () => {
                            const audioBlob = new Blob(audioChunks);
                            const audioUrl = URL.createObjectURL(audioBlob);
                            const audio = new Audio(audioUrl);
                            const play = () => audio.play();
                            resolve({ audioBlob, audioUrl, play });
                        });

                        mediaRecorder.stop();
                    });
                }
                return resolve({ start, stop });
            } catch (error) {
                console.error(error);
                console.log('audio recording failed');
                return reject();
            }
        });
    }

    async function updateOrder() {
        setDisplayErrMsg(false);
        setErrMsg(stdErrMsg);
        if (fileList.length < 1 && !audioState?.audio?.audioBlob && !location.state?.filesOnly) {
            //go to next page without doing anything
            setDisplaySuccessMsg(true);
            return setTimeout(() => navigate('/appointments'), 500);
        }
        if (location.state?.filesOnly && fileList.length < 1 && !audioState?.audio?.audioBlob) {
            setDisplaySuccessMsg(false);
            setErrMsg(
                'Könnte es sein, dass Sie vergessen haben eine Datei auszuwählen oder eine Sprachnachricht aufzunehmen?'
            );
            setDisplayErrMsg(true);
            return;
        }
        if (location.state?.filesOnly && fileNoteState === '') {
            setDisplaySuccessMsg(false);
            setErrMsg('Bitte mindestens einen Identifikator angeben :-)');
            setDisplayErrMsg(true);
            return;
        }
        setSuccessMsg(
            'Upload läuft! Bitte warten und die Seite nicht schließen! Weiterleitung erfolgt automatisch.'
        );
        setDisplaySuccessMsg(true);
        try {
            let response;
            const data = new FormData();
            files.forEach((file, i) => {
                data.append(`file${i}`, file, file.name);
            });
            if (audioState?.audio?.audioBlob) {
                data.append(`audio`, audioState.audio.audioBlob, 'audio.mp3');
                console.log('audio included!');
            }
            if (fileNoteState !== '') data.append('fileNote', fileNoteState);
            response = await axios.post(`/order/fileUpload?orderId=${orderId}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${auth?.token}`,
                },
                onUploadProgress: progressEvent => {
                    const progress = (progressEvent.loaded / progressEvent.total) * 100;
                    setProgress(progress);
                },
                onDownloadProgress: progressEvent => {
                    const progress = (progressEvent.loaded / progressEvent.total) * 100;
                    setProgress(progress);
                },
            });
            if (response.status === 200) {
                setDisplaySuccessMsg(true);
                setErrMsg(stdErrMsg);
                setSuccessMsg(stdSuccessMsg);
                if (location.state?.filesOnly)
                    return setTimeout(() => navigate('/orderViewSingle'), 500);
                return setTimeout(() => navigate('/appointments'), 500);
            }
        } catch (error) {
            setDisplaySuccessMsg(false);
            // on failure: print error and display error message
            if (!error?.response) {
                setErrMsg('Server konnte nicht erreicht werden oder die Dateigröße ist zu hoch!');
            }
            if (error?.response?.status === 401) {
                setErrMsg(stdErrMsg);
                return logout(auth, setAuth, setUserState);
            }
            console.error(error);
            setDisplayErrMsg(true);
        }
    }

    const [fileList, setFileList] = useState([]);

    function handleFileChange(e) {
        setFileList([...fileList, ...e.target.files]);
    }

    //TODO: maybe rework to use fileList only?
    const files = fileList ? [...fileList] : [];

    function handleUploadClick() {
        inputRef.current?.click();
    }

    async function startRecording() {
        let recorder;
        try {
            recorder = await recordAudio();
            recorder.start();
            setAudioState({ ...audioState, isRecording: true, recorder: recorder });
        } catch (error) {
            console.error(error);
            setAudioState({ ...audioState, isRecording: false });
            setErrMsg(
                'Konnte die Aufnahme nicht starten! Ist der Zugriff auf das Mikrofon erlaubt?'
            );
            setDisplayErrMsg(true);
        }
    }

    async function stopRecording() {
        try {
            const audio = await audioState.recorder.stop();
            setAudioState({ ...audioState, isRecording: false, audio: audio });
        } catch (error) {
            console.log(error);
            console.log('stopping recording failed');
        }
    }

    return (
        <div>
            <div className="relative flex flex-col gap-4 items-center min-h-screen ">
                <input
                    ref={inputRef}
                    type="file"
                    onChange={handleFileChange}
                    multiple
                    className="hidden"
                />
                <h1>Photos oder andere Dateien hochladen</h1>
                <div className="flex flex-col justify-center ">
                    <Button
                        className="mb-1"
                        onClick={handleUploadClick}
                        outline={true}
                        gradientDuoTone="cyanToBlue"
                    >
                        Photos / Dateien auswählen oder hinzufügen
                    </Button>
                    {files?.length > 0 && (
                        <Button
                            onClick={() => setFileList([])}
                            outline={true}
                            gradientDuoTone="redToYellow"
                        >
                            Dateiliste leeren
                        </Button>
                    )}
                </div>
                <div className="flex flex-col mb-4">
                    {files?.length > 0 && <p className="underline mb-2"> Ausgewählt:</p>}
                    <ul>
                        {files.map((file, i) => (
                            <li key={i}>
                                {file.name}
                                {file.size > maxFileSize ? (
                                    <span className="text-red-500">
                                        {' '}
                                        - Diese Datei ist zu groß! (Max. 1GB)
                                    </span>
                                ) : (
                                    ''
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="w-full">
                    <h1>und Sprachaufnahme erfassen</h1>
                    <div className="pt-4 flex justify-center">
                        <MicrophoneIcon
                            className={`h-28 hover:cursor-pointer ${
                                audioState.isRecording
                                    ? ' text-red-500'
                                    : ' hover:text-cyan-500 text-blue-500'
                            }`}
                            onClick={() =>
                                audioState.isRecording ? stopRecording() : startRecording()
                            }
                        />
                    </div>
                    <div className="mt-8">
                        {audioState.isRecording ? (
                            <p>Aufnahme läuft...</p>
                        ) : (
                            audioState.audio && <p>Aufnahme überprüfen:</p>
                        )}
                    </div>
                    <div className="mt-8 w-full">
                        {audioState.audio && (
                            <audio controls className="w-full bg-black rounded">
                                <source src={audioState?.audio?.audioUrl} type="audio/mp3" />
                            </audio>
                        )}
                    </div>
                    {(audioState.audio || files.length > 0) && (
                        <div>
                            <div className="mb-2 mt-8 block">
                                <div className="font-bold">
                                    Identifikator oder Hinweise zu den Dateien
                                </div>
                            </div>
                            <TextInput
                                icon={ChatBubbleBottomCenterTextIcon}
                                placeholder="z.B. Fotos vom Maxl"
                                value={fileNoteState}
                                onChange={e => setFileNoteState(e.target.value)}
                                name="notes"
                            />
                        </div>
                    )}
                </div>
                <div>
                    <DefaultAlerts
                        displaySuccessMsg={displaySuccessMsg}
                        displayErrMsg={displayErrMsg}
                        errMsg={errMsg}
                        successMsg={successMsg}
                    />
                </div>
                {progress > 0 && (
                    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div
                            className="bg-blue-600 h-2.5 rounded-full"
                            style={{ width: progress + '%' }}
                        ></div>
                    </div>
                )}
                <ContinueButtonGroup
                    onClickBack={() => navigate(-1)}
                    onClickForward={() => {
                        updateOrder();
                    }}
                />
            </div>
        </div>
    );
}
