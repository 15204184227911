import { Button, Checkbox, Label, Modal, Select, TextInput } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const toothColors = [
    'bekannt',
    'siehe Fotos',
    'OM1',
    'OM2',
    'OM3',
    'A1',
    'A2',
    'A3',
    'A3.5',
    'A4',
    'B1',
    'B2',
    'B3',
    'B3.5',
    'B4',
    'C1',
    'C2',
    'C3',
    'C3.5',
    'C4',
    'D1',
    'D2',
    'D3',
    'D3.5',
    'D4',
];

export const possibleRestorations = [
    '-',
    'ZK',
    'ZKM',
    'BZ',
    'V',
    'VKTK',
    'VKI',
    'VKB',
    'KM',
    'BM',
    'PR',
    'MU',
    'SK/T',
    'SK/S',
    'SK/M',
    'IWU',
    'WU',
    'GK',
    'GTK',
    'SW',
    'SH',
    'SHW',
];

const materials = ['-', 'Zirkon', 'Lithiumdisilikat', 'Gold', 'Titan', 'Kunststoff', 'Sonstiges'];

export default function ToothModal({ restorations, openModal, setOpenModal }) {
    const [currentRestoration, setCurrentRestoration] = useState({
        toothColor: 'A1',
        restoration: 'KM',
        restorationMaterial: '-',
        restorationNote: '',
        isMissing: false,
    });

    const navigate = useNavigate();

    //um hier das rerender zu verhindern ist das uncontrolled input, aber dennoch controlled
    const inputRef = useRef(null);
    let inputValue = currentRestoration.restorationNote;
    //setze input value bei render
    //EXPERIMENTELL
    if (inputRef.current?.value !== undefined) inputRef.current.value = inputValue;

    function submitInput() {
        setCurrentRestoration({ ...currentRestoration, restorationNote: inputValue });
    }

    function handleSubmit(e) {
        submitInput();
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());
        openModal.teeth.forEach(tooth => {
            const index = restorations.findIndex(r => r.tooth === tooth);
            if (index !== -1) restorations.splice(index, 1);
            restorations.push({
                tooth: tooth,
                toothColor: formJson.toothColor,
                restoration: formJson.restoration,
                restorationMaterial: formJson.restorationMaterial,
                restorationNote: formJson.restorationNote,
                //its getting the value of the checkbox, which is either "on" or undefined
                isMissing: formJson.isMissing === 'on',
            });
        });
        closeModal();
    }

    function closeModal() {
        setOpenModal({ open: false, teeth: [] });
        setCurrentRestoration({ ...currentRestoration, restorationNote: '' });
    }

    useEffect(() => {
        const restorationFound = restorations.find(e => e.tooth === openModal.teeth[0]);
        if (restorationFound) setCurrentRestoration({ ...restorationFound });
    }, [restorations, openModal.teeth, openModal.open]);

    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setCurrentRestoration({
            ...currentRestoration,
            [name]: value,
            restorationNote: inputValue,
        });
    }

    return (
        <div key="testkey">
            <Modal show={openModal.open} size="xl" key="modal0101" onClose={() => closeModal()}>
                <form
                    method="post"
                    className="flex flex-col gap-4"
                    key="modalForm"
                    onSubmit={handleSubmit}
                >
                    <Modal.Header>{`Auswahl: ${openModal.teeth.join()}`}</Modal.Header>
                    <Modal.Body key="modalBody">
                        <div className="space-y-6" key="modalDivContainer">
                            <h3 className="text-center text-xl font-medium text-gray-900 dark:text-white">
                                Details zur Restauration
                            </h3>
                            <div className="flex justify-between">
                                <div className="flex items-center gap-4">
                                    <Checkbox
                                        key="isMissingCheckbox"
                                        id="isMissing"
                                        name="isMissing"
                                        checked={currentRestoration.isMissing}
                                        onChange={handleChange}
                                    />
                                    <Label htmlFor="isMissing">Zahn fehlt</Label>
                                </div>
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="toothColor" value="Zahnfarbe" />
                                </div>
                                <Select
                                    key="toothColorSelect"
                                    id="toothColor"
                                    required
                                    value={currentRestoration.toothColor}
                                    onChange={handleChange}
                                    name="toothColor"
                                >
                                    {toothColors.map(color => (
                                        <option key={color}>{color}</option>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <div className="flex justify-between">
                                        <Label htmlFor="restorationSelect" value="Restauration" />
                                        <Label
                                            value="(Legende)"
                                            className="hover:cursor-pointer"
                                            onClick={() => navigate('/legend')}
                                        />
                                    </div>
                                </div>
                                <Select
                                    key="restorationSelect"
                                    id="restorationSelect"
                                    required
                                    value={currentRestoration.restoration}
                                    name="restoration"
                                    onChange={handleChange}
                                >
                                    {possibleRestorations.map(rest => (
                                        <option key={rest}>{rest}</option>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="materialSelect" value="Material" />
                                </div>
                                <Select
                                    key="materialSelect"
                                    id="materialSelect"
                                    required
                                    value={currentRestoration.restorationMaterial}
                                    name="restorationMaterial"
                                    onChange={handleChange}
                                >
                                    {materials.map(mat => (
                                        <option key={mat}>{mat}</option>
                                    ))}
                                </Select>
                            </div>
                            <div key="inputContainer">
                                <div className="mb-2 block">
                                    <Label value="Notiz" />
                                </div>
                                <TextInput
                                    ref={inputRef}
                                    id="restorationNoteTextInput"
                                    key="restorationNoteTextInput0"
                                    name="restorationNote"
                                    onChange={e => (inputValue = e.target.value)}
                                    placeholder="optionale Notiz..."
                                />
                            </div>
                            <div>
                                <div className="flex w-full justify-center">
                                    <Button
                                        type="submit"
                                        outline={true}
                                        gradientDuoTone="cyanToBlue"
                                    >
                                        Übernehmen
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </form>
            </Modal>
        </div>
    );
}
