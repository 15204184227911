import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useUser from '../hooks/use-user';
import { USER_ROLE } from './require-role';

export default function HomeView() {
    const { userState } = useUser();
    const navigate = useNavigate();

    //TODO: use location state from require auth
    useEffect(() => {
        // setTimeout(() => {
        //     switch (userState.userRole) {
        //         case USER_ROLE.DOC:
        //             return navigate('/');
        //         case USER_ROLE.LAB:
        //             return navigate('/lab/');
        //         default:
        //             return navigate('/editUser');
        //     }
        // }, 900);
        switch (userState.userRole) {
            case USER_ROLE.DOC:
                return navigate('/');
            case USER_ROLE.LAB:
                return navigate('/lab/');
            default:
                return navigate('/editUser');
        }
    });

    return (
        <div className="flex flex-col gap-4 items-center min-h-screen">
            <h1> moving ... </h1>
        </div>
    );
}
