import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import useErrMsg from '../hooks/use-errormsg';
import useOrder from '../hooks/use-order';
import logout from '../utils/logout';
import axios from '../utils/axios';
import { Table } from 'flowbite-react';
import { Button } from 'flowbite-react';
import { TableBody } from 'flowbite-react/lib/esm/components/Table/TableBody';
import DefaultAlerts from './default-alerts';
import { OrderTableHead, OrderRow, PaginationWithIcons } from './orderview';
import useUser from '../hooks/use-user';
import { TableRow } from 'flowbite-react/lib/esm/components/Table/TableRow';
import { TableCell } from 'flowbite-react/lib/esm/components/Table/TableCell';
import { TableHead } from 'flowbite-react/lib/esm/components/Table/TableHead';
import { TableHeadCell } from 'flowbite-react/lib/esm/components/Table/TableHeadCell';
import { downloadOrderFiles, SingleOrderView } from './order-view-single';

export default function LabView() {
    const [displayErrMsg, setDisplayErrMsg] = useState(false);
    const [displaySuccessMsg] = useState(false);
    const { errMsg, setErrMsg, successMsg, stdErrMsg } = useErrMsg();
    const { auth, setAuth } = useAuth();
    const { userState, setUserState } = useUser();
    const [orders, setOrders] = useState([]);
    const [ordersCount, setOrdersCount] = useState(0);
    const [ordersLoaded, setOrdersLoaded] = useState(false);
    const navigate = useNavigate();
    const { activeOrder, setActiveOrder } = useOrder();
    const [currentPage, setCurrentPage] = useState(1);
    const [activeAssignedDoc, setActiveAssignedDoc] = useState(userState.assignedDocs[0]);
    const [orderLoaded, setOrderLoaded] = useState(false);
    const [progress, setProgress] = useState(0);

    function setDocActive(doc) {
        setActiveAssignedDoc(doc);
        setOrdersLoaded(false);
    }

    const getOrders = useCallback(
        async function getOrders() {
            try {
                const response = await axios.get(
                    `/order/?page=${currentPage}&limit=10&assignedDocId=${activeAssignedDoc?.docId}`,
                    {
                        headers: { Authorization: `Bearer ${auth?.token}` },
                    }
                );
                if (response.status === 200) {
                    setOrders(response.data.data);
                    setOrdersCount(response.data.count);
                    setErrMsg(stdErrMsg);
                }
            } catch (error) {
                // on failure: print error and display error message
                if (!error?.response) {
                    setErrMsg('Server konnte nicht erreicht werden.');
                }
                if (error?.response?.status === 401) {
                    setErrMsg(stdErrMsg);
                    return logout(auth, setAuth, setUserState);
                }
                console.error(error);
                setDisplayErrMsg(true);
            }
        },
        [currentPage, activeAssignedDoc, auth, setErrMsg, stdErrMsg, setAuth, setUserState]
    );

    async function getOrderAndDisplay(orderId) {
        try {
            const response = await axios.get(`/order/${orderId}`, {
                headers: { Authorization: `Bearer ${auth?.token}` },
            });
            if (response.status === 200) {
                const { _id, ...body } = response.data;
                setActiveOrder({ ...body, orderId: _id });
                setErrMsg(stdErrMsg);
                setOrderLoaded(true);
                const coords = document
                    .getElementById('orderViewSingleDiv')
                    .getBoundingClientRect();
                window.scrollTo({ top: coords.y + window.scrollY, behavior: 'smooth' });
                await axios.post(
                    '/order/setOrderSeen',
                    { orderId, seenByLab: true },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${auth?.token}`,
                        },
                    }
                );
                getOrders()
            }
        } catch (error) {
            // on failure: print error and display error message
            if (!error?.response) {
                setErrMsg('Server konnte nicht erreicht werden.');
            }
            if (error?.response?.status === 401) {
                setErrMsg(stdErrMsg);
                return logout(auth, setAuth, setUserState);
            }
            console.error(error);
            setDisplayErrMsg(true);
        }
    }

    useEffect(() => {
        if (!ordersLoaded) {
            getOrders();
            setOrdersLoaded(true);
        }
    }, [ordersLoaded, getOrders, activeOrder]);

    function DocRow({ doc, setDocActive }) {
        return (
            <TableRow onClick={() => setDocActive(doc)} className="hover:cursor-pointer">
                <TableCell key={`DocCellDocName${doc.docName}`}>{`${doc.docName}`}</TableCell>
                <TableCell key={`DocCellDocId${doc.docId}`}>{`${doc.docId}`}</TableCell>
            </TableRow>
        );
    }

    return (
        <div>
            <div className="flex flex-col flex-nowrap gap-4 items-center pb-10">
                <h1>Zugeordneten Nutzer auswählen</h1>
                <div className="overflow-x-scroll w-full">
                    {userState.assignedDocs.length > 0 && (
                        <Table hoverable>
                            <TableHead>
                                <TableHeadCell>Nutzer</TableHeadCell>
                                <TableHeadCell>Id</TableHeadCell>
                            </TableHead>
                            <TableBody>
                                {userState.assignedDocs.map(doc => {
                                    return (
                                        <DocRow
                                            doc={doc}
                                            key={doc.docId}
                                            setDocActive={setDocActive}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    )}
                </div>
            </div>
            <div className="flex flex-col flex-nowrap gap-4 items-center pb-10">
                {orders.length > 0 && <h1>Aufträge von {activeAssignedDoc?.docName} </h1>}
                <div className="overflow-x-scroll w-full">
                    {orders.length > 0 && (
                        <Table hoverable>
                            <OrderTableHead />
                            <TableBody>
                                {orders.map(o => {
                                    return (
                                        <OrderRow
                                            order={o}
                                            key={o.id}
                                            getOrderAndNavigate={getOrderAndDisplay}
                                            bold={!o.seenByLab}
                                            active={o.id === activeOrder.orderId}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    )}
                </div>
                {orders.length > 0 && (
                    <PaginationWithIcons
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        ordersCount={ordersCount}
                        setOrdersLoaded={setOrdersLoaded}
                    />
                )}
            </div>
            <div id="orderViewSingleDiv"></div>
            <div className="flex flex-col flex-nowrap gap-4 items-center pb-10">
                <div className="grid sm:grid-cols-3 grid-cols-2 w-full">
                    <div className=""></div>
                    <div className="">
                        <h1> {orderLoaded && 'Auftragsübersicht'} </h1>
                    </div>
                    <div className="flex justify-end">
                        {orderLoaded && (
                            <Button
                                onClick={() => navigate('/lab/orderViewSingle')}
                                outline={true}
                                gradientDuoTone="cyanToBlue"
                            >
                                (nur Auftrag anzeigen)
                            </Button>
                        )}
                    </div>
                </div>
                <SingleOrderView
                    orderLoaded={orderLoaded}
                    loadedOrder={activeOrder}
                    progress={progress}
                    downloadOrderFiles={() =>
                        downloadOrderFiles(
                            activeOrder.orderId,
                            auth,
                            setAuth,
                            setUserState,
                            setErrMsg,
                            stdErrMsg,
                            setDisplayErrMsg,
                            setProgress
                        )
                    }
                />
            </div>
            <div className="mt-8">
                <DefaultAlerts
                    displaySuccessMsg={displaySuccessMsg}
                    displayErrMsg={displayErrMsg}
                    errMsg={errMsg}
                    successMsg={successMsg}
                />
            </div>
        </div>
    );
}
