import { Table } from 'flowbite-react';
import { TableBody } from 'flowbite-react/lib/esm/components/Table/TableBody';
import { TableCell } from 'flowbite-react/lib/esm/components/Table/TableCell';
import { TableHead } from 'flowbite-react/lib/esm/components/Table/TableHead';
import { TableHeadCell } from 'flowbite-react/lib/esm/components/Table/TableHeadCell';
import { TableRow } from 'flowbite-react/lib/esm/components/Table/TableRow';
import React, { useEffect } from 'react';
import { possibleRestorations } from './tooth-modal';

const possibleRestorationsLegend = [
    '-',
    'Krone Zirkonkeramik',
    'Zirkonkrone monolithisch',
    'Brückenglied Zirkon',
    'Veneer',
    'Vollkeramik Teilkrone',
    'Vollkeramik Inlay',
    'Vollkeramik Brückenglied',
    'Krone Metallkeramik',
    'Metallkeramik Brückenglied',
    'Provisorium',
    'Mock-up',
    'Suprakonstruktion Teleskop',
    'Suprakonstruktion Steg',
    'Suprakonstruktion Metallkeramik',
    'Implantat Wax-Up',
    'Wax-Up',
    'Goldkrone',
    'Gold Teilkrone',
    'Schiene weich',
    'Schiene hart',
    'Schiene weich + hart',
];
export default function LegendView() {
    useEffect(() => window.scrollTo(0, 0));
    return (
        <div className="flex flex-col gap-4 items-center min-h-screen">
            <h1> Legende </h1>
            <div className="overflow-x-scroll justify-center w-full p-1">
                <Table hoverable>
                    <TableHead>
                        <TableHeadCell>Kürzel</TableHeadCell>
                        <TableHeadCell>Ausgeschrieben</TableHeadCell>
                    </TableHead>
                    <TableBody className="bg-white divide-y">
                        {possibleRestorations.map((r, i) => {
                            return (
                                <TableRow key={`row-${i}`}>
                                    <TableCell key={`${r}-${i}`}>{r}</TableCell>
                                    <TableCell key={i}>{possibleRestorationsLegend[i]}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}
