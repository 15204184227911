import { Table } from 'flowbite-react';
import { TableBody } from 'flowbite-react/lib/esm/components/Table/TableBody';
import { TableCell } from 'flowbite-react/lib/esm/components/Table/TableCell';
import { TableHead } from 'flowbite-react/lib/esm/components/Table/TableHead';
import { TableHeadCell } from 'flowbite-react/lib/esm/components/Table/TableHeadCell';
import { TableRow } from 'flowbite-react/lib/esm/components/Table/TableRow';
import { useEffect } from 'react';

const technicalDetails = [
    {
        title: 'Testphase',
        tip: 'Dieses Produkt befindet sich derzeit in der Testphase.',
    },
    {
        title: 'Verschlüsselung',
        tip: 'Sämtliche Kommunikation eines Endnutzergerätes (z.B. Tablet, Handy, PC) mit dieser Webapplikation findet verschlüsselt statt. Dazu wird das kryptographische Protokoll Transport Layer Security (TLS) in den vom Bundesamt für Sicherheit in der Informationstechnik (BSI) geforderten Versionen TLS 1.2 und TLS 1.3 eingesetzt.¹ Bei modernen Browsern lassen sich, in der Regel, Informationen zur von einer Webapplikation verwendeten Verschlüsselung anhand des Schloss-Symbol neben der Addressleiste auslesen. Insbesondere der Firefox-Browser bietet hier umfangreiche Informationen. Gleichzeitig werden in der Regel Websites, die kein oder ein überholtes Verschlüsselungsverfahren nutzen, mit einer entsprechenden Warnung vom modernen Browser geblockt.',
    },
    {
        title: 'Datenverarbeitung',
        tip: 'Sämtliche, über das Digitale Auftragsformular hochgeladenen Daten werden auf einem ausschließlich zu diesem Zweck angemieteten Server in einem professionell betriebenen Rechenzentrum der 1Blu AG in Frankfurt, Deutschland gespeichert. Die 1Blu AG verpflichtet sich, die Daten spätestens zwei Wochen nach Beendigung des Mietvertrags zu löschen.² Die Daten werden im Rahmen des Informationsstransfers von Aufträgen, Kostenvoranschlägen, Dokumenten oder Terminplanungen an Benutzerkonten zugeordnete Dentallabore verarbeitet. Eine anderweitige Datenspeicherung oder Datenverarbeitung findet nicht statt.',
    },
    {
        title: 'Zugriff auf gespeicherte Daten',
        tip: 'Zugriff auf gespeicherte Daten haben Benutzer mit den Zugangsdaten des Accounts, von dem die Daten hochgeladen wurden (Praxiszugang), Benutzer mit einem Labor-Account, welcher dem hochladenden Account zugeordnet ist (Laborzugang) und der Entwickler des Digitalen Auftragsformulars.',
    },
    {
        title: 'Auskunft, Widerspruch und Löschung',
        tip: 'Auf Anfrage kann der Datenverarbeitung widersprochen, die Löschung oder die Übertragung der gespeicherten Daten gefordert oder Auskunft über die gespeicherten Daten erteilt werden.',
    },
    {
        title: 'Datenschutzerklärung & AGB',
        tip: '... sind in Arbeit.',
    },
    {
        title: '',
        tip: (
            <div>
                <p>
                    ¹:
                    https://www.bsi.bund.de/SharedDocs/Downloads/DE/BSI/Mindeststandards/Mindeststandard_BSI_TLS_Version_2_4.pdf?__blob=publicationFile&v=4
                </p>
                <p>²: https://www.1blu.de/datenschutz/</p>
            </div>
        ),
    },
];

export default function TechnicalDetailsView() {
    useEffect(() => window.scrollTo(0, 0));
    return (
        <div className="flex flex-col gap-4 items-center min-h-screen">
            <h1> Technische Details & Datenschutz </h1>
            <div className="overflow-x-scroll justify-center w-full p-1">
                <Table hoverable>
                    <TableHead>
                        <TableHeadCell>Thema</TableHeadCell>
                        <TableHeadCell>Details</TableHeadCell>
                    </TableHead>
                    <TableBody className="bg-white divide-y">
                        {technicalDetails.map((r, i) => {
                            return (
                                <TableRow key={`row-${i}`}>
                                    <TableCell key={`${r.title}`}>{r.title}</TableCell>
                                    <TableCell key={`${r.tip}`}>{r.tip}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}
