import './App.css';
import { Flowbite } from 'flowbite-react';
import AppRouter from './components/app-router';
import AllroundContainer from './components/allround-container';

function App() {
    return (
        <div className="App">
            <Flowbite>
                <AllroundContainer>
                    <AppRouter />
                </AllroundContainer>
            </Flowbite>
        </div>
    );
}

export default App;
