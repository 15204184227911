import { Button } from 'flowbite-react';

export default function ContinueButtonGroup({ onClickBack, onClickForward, forwardButtonText }) {
    return (
        <div className="flex justify-between w-full p-10">
            <Button onClick={onClickBack} outline={true} gradientDuoTone="cyanToBlue">
                Zurück
            </Button>
            <Button onClick={onClickForward} outline={true} gradientDuoTone="cyanToBlue">
                {forwardButtonText ?? "Übernehmen"}
            </Button>
        </div>
    );
}
