import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import useErrMsg from '../hooks/use-errormsg';
import useOrder from '../hooks/use-order';
import logout from '../utils/logout';
import axios from '../utils/axios';
import { TableRow } from 'flowbite-react/lib/esm/components/Table/TableRow';
import { TableCell } from 'flowbite-react/lib/esm/components/Table/TableCell';
import { Button, Table } from 'flowbite-react';
import { TableBody } from 'flowbite-react/lib/esm/components/Table/TableBody';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { TableHeadCell } from 'flowbite-react/lib/esm/components/Table/TableHeadCell';
import { TableHead } from 'flowbite-react/lib/esm/components/Table/TableHead';
import DefaultAlerts from './default-alerts';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/solid';
import useUser from '../hooks/use-user';

export function OrderTableHead() {
    return (
        <TableHead>
            <TableHeadCell>Benutzer</TableHeadCell>
            <TableHeadCell>Autor</TableHeadCell>
            <TableHeadCell>Patient</TableHeadCell>
            <TableHeadCell>Patienten-ID</TableHeadCell>
            <TableHeadCell>Letzte Änderung</TableHeadCell>
            <TableHeadCell>Freigabe erteilt</TableHeadCell>
            <TableHeadCell>Terminplanung erstellt</TableHeadCell>
            <TableHeadCell>KV angefragt</TableHeadCell>
        </TableHead>
    );
}

export function PaginationWithIcons({ currentPage, setCurrentPage, setOrdersLoaded, ordersCount }) {
    return (
        <>
            <Button.Group outline className="mt-6">
                <Button
                    className="bg-cyan-500 hover:bg-cyan-500"
                    onClick={() => {
                        setCurrentPage(c => (c > 1 ? (c -= 1) : c));
                        setOrdersLoaded(false);
                    }}
                >
                    <ChevronDoubleLeftIcon className="mr-3 h-4" />
                    <p>Vorherige</p>
                </Button>
                <Button gradientDuoTone="cyanToBlue" className="hover:cursor-default">
                    <p>{`${(currentPage - 1) * 10}-${
                        currentPage * 10 > ordersCount ? ordersCount : currentPage * 10
                    } von ${ordersCount}`}</p>
                </Button>
                <Button
                    className="bg-blue-400 hover:bg-blue-500"
                    onClick={() => {
                        setCurrentPage(c => ((c + 1) * 10 - ordersCount < 10 ? (c += 1) : c));
                        setOrdersLoaded(false);
                    }}
                >
                    <p>Weitere</p>
                    <ChevronDoubleRightIcon className="ml-3 h-4" />
                </Button>
            </Button.Group>
        </>
    );
}

export function OrderRow({ order, getOrderAndNavigate, bold, active }) {
    const fontStyle = bold ? 'font-bold' : ''
    const activeBg = active ? 'bg-gray-100' : ''
    const tableRowClassName = `hover:cursor-pointer ${fontStyle} ${activeBg}`
    return (
        <TableRow onClick={() => getOrderAndNavigate(order.id)} className={tableRowClassName}>
            <TableCell key={`OrderCellUserName${order.id}`}>{`${order.userName}`}</TableCell>
            <TableCell key={`OrderCellAuthor${order.id}`}>{`${order.author}`}</TableCell>
            <TableCell key={`OrderCellPatientName${order.id}`}>{`${order.patientName}`}</TableCell>
            <TableCell key={`OrderCellPatientId${order.id}`}>{`${order.patientId}`}</TableCell>
            <TableCell key={`OrderCellUpdatedAt${order.id}`}>
                {new Date(order.updatedAt).toLocaleDateString('de-DE')}
            </TableCell>
            <TableCell key={`OrderCellOrdered${order.id}`}>
                {order.ordered ? (
                    <CheckIcon className="h-8 text-green-500" />
                ) : (
                    <XMarkIcon className="h-8 text-red-500" />
                )}
            </TableCell>
            <TableCell key={`OrderCellAppointmentsCreated${order.id}`}>
                {order.appointments ? (
                    <CheckIcon className="h-8 text-green-500" />
                ) : (
                    <XMarkIcon className="h-8 text-red-500" />
                )}
            </TableCell>
            <TableCell key={`OrderCellOfferRequestedAt${order.id}`}>
                {order.offerRequestedAt ? (
                    <CheckIcon className="h-8 text-green-500" />
                ) : (
                    <XMarkIcon className="h-8 text-red-500" />
                )}
            </TableCell>
        </TableRow>
    );
}

export default function OrderView() {
    const [displayErrMsg, setDisplayErrMsg] = useState(false);
    const [displaySuccessMsg] = useState(false);
    const { errMsg, setErrMsg, successMsg, stdErrMsg } = useErrMsg();
    const { auth, setAuth } = useAuth();
    const { setUserState } = useUser();
    const [orders, setOrders] = useState([]);
    const [ordersCount, setOrdersCount] = useState(0);
    const [ordersLoaded, setOrdersLoaded] = useState(false);
    const navigate = useNavigate();
    const { activeOrder, setActiveOrder } = useOrder();
    const [currentPage, setCurrentPage] = useState(1);

    const getOrders = useCallback(
        async function getOrders() {
            try {
                const response = await axios.get(`/order/?page=${currentPage}&limit=10`, {
                    headers: { Authorization: `Bearer ${auth?.token}` },
                });
                if (response.status === 200) {
                    setOrders(response.data.data);
                    setOrdersCount(response.data.count);
                    setErrMsg(stdErrMsg);
                }
            } catch (error) {
                // on failure: print error and display error message
                if (!error?.response) {
                    setErrMsg('Server konnte nicht erreicht werden.');
                }
                if (error?.response?.status === 401) {
                    setErrMsg(stdErrMsg);
                    return logout(auth, setAuth, setUserState);
                }
                console.error(error);
                setDisplayErrMsg(true);
            }
        },
        [auth, setAuth, setErrMsg, stdErrMsg, currentPage, setUserState]
    );

    async function getOrderAndNavigate(orderId) {
        try {
            const response = await axios.get(`/order/${orderId}`, {
                headers: { Authorization: `Bearer ${auth?.token}` },
            });
            if (response.status === 200) {
                const { _id, ...body } = response.data;
                setActiveOrder({ ...body, orderId: _id });
                setErrMsg(stdErrMsg);
                return navigate('/orderViewSingle');
            }
        } catch (error) {
            // on failure: print error and display error message
            if (!error?.response) {
                setErrMsg('Server konnte nicht erreicht werden.');
            }
            if (error?.response?.status === 401) {
                setErrMsg(stdErrMsg);
                return logout(auth, setAuth, setUserState);
            }
            console.error(error);
            setDisplayErrMsg(true);
        }
    }

    useEffect(() => {
        if (!ordersLoaded) {
            getOrders();
            setOrdersLoaded(true);
        }
    }, [ordersLoaded, getOrders, activeOrder]);

    return (
        <div>
            <div className="flex flex-col flex-nowrap gap-4 min-h-screen items-center">
                <h1>Alle Aufträge</h1>
                <div className="overflow-x-scroll w-full p-1">
                    {orders.length > 0 && (
                        <Table hoverable>
                            <OrderTableHead />
                            <TableBody className="bg-white divide-y">
                                {orders.map(o => {
                                    return (
                                        <OrderRow
                                            order={o}
                                            getOrderAndNavigate={getOrderAndNavigate}
                                            key={o.id}
                                            bold={false}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    )}
                </div>
                <PaginationWithIcons
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setOrdersLoaded={setOrdersLoaded}
                    ordersCount={ordersCount}
                />
                <div className="mt-8">
                    <DefaultAlerts
                        displaySuccessMsg={displaySuccessMsg}
                        displayErrMsg={displayErrMsg}
                        errMsg={errMsg}
                        successMsg={successMsg}
                    />
                </div>
            </div>
        </div>
    );
}
