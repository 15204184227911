import { EnvelopeIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { TextInput, Label, Button, Alert } from 'flowbite-react';
import axios from '../utils/axios';
import { useState } from 'react';
import useAuth from '../hooks/useAuth';
import logout from '../utils/logout';
import useUser from '../hooks/use-user';
import useErrMsg from '../hooks/use-errormsg';

export default function EditUser() {
    const [displayErrMsg, setDisplayErrMsg] = useState(false);
    const [displaySuccessMsg, setDisplaySuccessMsg] = useState(false);
    const { errMsg, setErrMsg, stdErrMsg } = useErrMsg();

    const { auth, setAuth } = useAuth();

    const { userState, setUserState } = useUser();

    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        setUserState({
            [name]: value,
        });
    }

    async function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());

        try {
            const response = await axios.patch('/user/edit', JSON.stringify(formJson), {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth?.token}`,
                },
            });
            if (response.status === 200) {
                setDisplaySuccessMsg(true);
                setErrMsg(stdErrMsg);
                return setTimeout(() => logout(auth, setAuth, setUserState), 1000);
            }
        } catch (error) {
            // on failure: print error and display error message
            if (!error?.response) {
                setErrMsg('Server konnte nicht erreicht werden.');
            }
            if (error.response.status === 401) {
                setErrMsg(stdErrMsg);
                return logout(auth, setAuth, setUserState);
            }
            console.error(error);
            setDisplayErrMsg(true);
        }
    }

    return (
        <div>
            <div className="flex flex-col gap-4 items-center min-h-screen">
                <h1>Benutzer-Einstellungen</h1>
                <form method="post" className="flex flex-col gap-4" onSubmit={handleSubmit}>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="email1" value="E-Mail" />
                        </div>
                        <TextInput
                            icon={EnvelopeIcon}
                            id="email1"
                            name="email"
                            type="email"
                            placeholder="name@domain.de"
                            required={true}
                            value={userState.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="name1" value="Name" />
                        </div>
                        <TextInput
                            icon={UserIcon}
                            id="name1"
                            name="name"
                            required={true}
                            value={userState.name}
                            onChange={handleChange}
                        />
                    </div>
                    <Button outline={true} gradientDuoTone="cyanToBlue" type="submit">
                        Absenden
                    </Button>
                    {displayErrMsg && (
                        <Alert color="failure" icon={XMarkIcon}>
                            <span>
                                <p>{errMsg}</p>
                            </span>
                        </Alert>
                    )}
                    {displaySuccessMsg && (
                        <Alert color="success">
                            <span>
                                <p>Erfolgreich editiert! Weiterleitung zum Login</p>
                            </span>
                        </Alert>
                    )}
                </form>
            </div>
        </div>
    );
}
