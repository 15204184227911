import { useNavigate } from 'react-router-dom';
import DefaultAlerts from './default-alerts';
import ContinueButtonGroup from './continue-bgroup';
import useOrder from '../hooks/use-order';
import useErrMsg from '../hooks/use-errormsg';
import { useEffect, useState } from 'react';
import { Label, RangeSlider, Textarea, TextInput, ToggleSwitch } from 'flowbite-react';
import { CubeTransparentIcon, EyeDropperIcon, UserIcon } from '@heroicons/react/24/solid';
import axios from '../utils/axios';
import useAuth from '../hooks/useAuth';
import logout from '../utils/logout';
import { ChatBubbleBottomCenterTextIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import useUser from '../hooks/use-user';

export default function AdditionalsView() {
    const [displayErrMsg, setDisplayErrMsg] = useState(false);
    const [displaySuccessMsg, setDisplaySuccessMsg] = useState(false);
    const { errMsg, setErrMsg, successMsg, stdErrMsg } = useErrMsg();

    const { activeOrder, setActiveOrder } = useOrder();
    const orderId = activeOrder.orderId;

    useEffect(() => {
        setAdditionalsState({
            impressionSystem: activeOrder.impressionSystem ?? '',
            xmlOrderNumber: activeOrder.xmlOrderNumber ?? '',
            outerToothColor: activeOrder.outerToothColor ?? '',
            innerToothColor: activeOrder.innerToothColor ?? '',
            notes: activeOrder.notes ?? '',
            aestheticExpectations: activeOrder.aestheticExpectations ?? 0.5,
            patientComingToLab: activeOrder.patientComingToLab ?? false,
            faceBowIncluded: activeOrder.faceBowIncluded ?? false,
            centricRegistrationIncluded: activeOrder.centricRegistrationIncluded ?? false,
            clinicalFindingsIncluded: activeOrder.clinicalFindingsIncluded ?? false,
            clinicalFindingsIncludedNote: activeOrder.clinicalFindingsIncludedNote ?? '',
            functionalFindingsIncluded: activeOrder.functionalFindingsIncluded ?? false,
            functionalFindingsIncludedNote: activeOrder.functionalFindingsIncludedNote ?? '',
            implantPassIncluded: activeOrder.implantPassIncluded ?? false,
            recallNeccessary: activeOrder.recallNeccessary ?? false,
            impressionDesinfected: activeOrder.impressionDesinfected ?? false,
            modelsIncluded: activeOrder.modelsIncluded ?? false,
            modelsIncludedNote: activeOrder.modelsIncludedNote ?? '',
            implantPartsIncluded: activeOrder.implantPartsIncluded ?? false,
            implantPartsIncludedNote: activeOrder.implantPartsIncludedNote ?? '',
            biteForkIncluded: activeOrder.biteForkIncluded ?? false,
        });
    }, [activeOrder]);

    const [additionalsState, setAdditionalsState] = useState({
        impressionSystem: '',
        xmlOrderNumber: '',
        outerToothColor: '',
        innerToothColor: '',
        notes: '',
        aestheticExpectations: 0.5,
        patientComingToLab: false,
        faceBowIncluded: false,
        centricRegistrationIncluded: false,
        clinicalFindingsIncluded: false,
        clinicalFindingsIncludedNote: '',
        functionalFindingsIncluded: false,
        functionalFindingsIncludedNote: '',
        implantPassIncluded: false,
        recallNeccessary: false,
        impressionDesinfected: false,
        modelsIncluded: false,
        modelsIncludedNote: '',
        implantPartsIncluded: false,
        implantPartsIncludedNote: '',
        biteForkIncluded: false,
    });
    function handleChange(event) {
        if (event.target) {
            const name = event.target.name;
            const value = event.target.value;
            setAdditionalsState({
                ...additionalsState,
                [name]: value,
            });
        } else if (event) {
        }
    }

    const { auth, setAuth } = useAuth();
    const { setUserState } = useUser();
    const navigate = useNavigate();

    async function updateOrder() {
        setErrMsg(stdErrMsg);
        try {
            let response;
            response = await axios.post(
                '/order/addAdditionalInformation',
                { orderId, ...additionalsState },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth?.token}`,
                    },
                }
            );
            if (response.status === 200) {
                const { _id, ...body } = response.data;
                setActiveOrder({ ...body, orderId: _id });
                setDisplaySuccessMsg(true);
                setErrMsg(stdErrMsg);
                return setTimeout(() => navigate('/fileUpload'), 500);
            }
        } catch (error) {
            // on failure: print error and display error message
            if (!error?.response) {
                setErrMsg('Server konnte nicht erreicht werden.');
            }
            if (error?.response?.status === 401) {
                setErrMsg(stdErrMsg);
                return logout(auth, setAuth, setUserState);
            }
            console.error(error);
            setDisplayErrMsg(true);
        }
    }

    return (
        <div>
            <div className="flex flex-col gap-4 min-h-screen items-center">
                <div className="lg:w-3/4 w-full">
                    <h1 className="pb-4">Zusätzliche Informationen</h1>
                    <div className="flex flex-col gap-4">
                        <div>
                            <div className="mb-2 block">
                                <Label value="Abformungssystem" />
                            </div>
                            <TextInput
                                icon={CubeTransparentIcon}
                                placeholder="konventionell / digital / .stl / ..."
                                value={additionalsState.impressionSystem}
                                onChange={handleChange}
                                name="impressionSystem"
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label value="Auftragsnummer zur Generierung der XML-Datei" />
                            </div>
                            <TextInput
                                icon={UserIcon}
                                placeholder="123456-007-ZE-..."
                                value={additionalsState.xmlOrderNumber}
                                onChange={handleChange}
                                name="xmlOrderNumber"
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label value="Zahnfarbe" />
                            </div>
                            <TextInput
                                icon={EyeDropperIcon}
                                placeholder="A1"
                                value={additionalsState.outerToothColor}
                                onChange={handleChange}
                                name="outerToothColor"
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label value="Darunterliegende Zahnfarbe" />
                            </div>
                            <TextInput
                                icon={EyeDropperIcon}
                                placeholder="A1"
                                value={additionalsState.innerToothColor}
                                onChange={handleChange}
                                name="innerToothColor"
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label value="Notizen" />
                            </div>
                            <Textarea
                                icon={ChatBubbleBottomCenterTextIcon}
                                placeholder="..."
                                value={additionalsState.notes}
                                onChange={handleChange}
                                name="notes"
                                rows={5}
                            />
                        </div>
                        <div className="m-4">
                            <div className="mb-2 block">
                                <Label value="Ästhetische Erwartungen" />
                            </div>
                            <RangeSlider
                                value={additionalsState.aestheticExpectations}
                                onChange={handleChange}
                                name="aestheticExpectations"
                            />
                            <div className="flex">
                                <div className="flex flex-col w-1/3 items-start">
                                    <Label value="eher niedrig" />
                                </div>
                                <div className="flex flex-col w-1/3 items-center">
                                    <Label value="mittelmäßig" />
                                </div>
                                <div className="flex flex-col w-1/3 items-end">
                                    <Label value="hoch" />
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-8 mt-4">
                            <div className="flex flex-col w-1/2 items-end">
                                <ToggleSwitch
                                    checked={additionalsState.impressionDesinfected}
                                    onChange={checked =>
                                        handleChange({
                                            target: {
                                                name: 'impressionDesinfected',
                                                value: checked,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className="flex flex-col w-1/2 items-start">
                                <Label value="Abformungen desinfiziert" />
                            </div>
                        </div>
                        <div className="flex gap-8 mt-4">
                            <div className="flex flex-col w-1/2 items-end">
                                <ToggleSwitch
                                    checked={additionalsState.recallNeccessary}
                                    onChange={checked =>
                                        handleChange({
                                            target: {
                                                name: 'recallNeccessary',
                                                value: checked,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className="flex flex-col w-1/2 items-start">
                                <Label value="Rücksprache notwending" />
                            </div>
                        </div>
                        <div className="flex gap-8 mt-4">
                            <div className="flex flex-col w-1/2 items-end">
                                <ToggleSwitch
                                    checked={additionalsState.patientComingToLab}
                                    onChange={checked =>
                                        handleChange({
                                            target: { name: 'patientComingToLab', value: checked },
                                        })
                                    }
                                />
                            </div>
                            <div className="flex flex-col w-1/2 items-start">
                                <Label value="Patient kommt zur Farbwahl ins Labor" />
                            </div>
                        </div>
                        <div className="flex gap-8 mt-4 w-full justify-start">
                            <div className="flex flex-col items-start">
                                <Label value="Mitgeliefert:" />
                            </div>
                        </div>
                        <div className="flex gap-8 mt-4">
                            <div className="flex flex-col w-1/2 items-end">
                                <ToggleSwitch
                                    checked={additionalsState.faceBowIncluded}
                                    onChange={checked =>
                                        handleChange({
                                            target: { name: 'faceBowIncluded', value: checked },
                                        })
                                    }
                                />
                            </div>
                            <div className="flex flex-col w-1/2 items-start">
                                <Label value="Gesichtsbogen" />
                            </div>
                        </div>
                        <div className="flex gap-8 mt-4">
                            <div className="flex flex-col w-1/2 items-end">
                                <ToggleSwitch
                                    checked={additionalsState.centricRegistrationIncluded}
                                    onChange={checked =>
                                        handleChange({
                                            target: {
                                                name: 'centricRegistrationIncluded',
                                                value: checked,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className="flex flex-col w-1/2 items-start">
                                <Label value="Zentrisches Registrat" />
                            </div>
                        </div>
                        <div className="flex gap-8 mt-4">
                            <div className="flex flex-col w-1/2 items-end">
                                <ToggleSwitch
                                    checked={additionalsState.biteForkIncluded}
                                    onChange={checked =>
                                        handleChange({
                                            target: {
                                                name: 'biteForkIncluded',
                                                value: checked,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className="flex flex-col w-1/2 items-start">
                                <Label value="Bissgabel" />
                            </div>
                        </div>
                        <div className="flex gap-8 mt-4">
                            <div className="flex flex-col w-1/2 items-end">
                                <ToggleSwitch
                                    checked={additionalsState.implantPassIncluded}
                                    onChange={checked =>
                                        handleChange({
                                            target: {
                                                name: 'implantPassIncluded',
                                                value: checked,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className="flex flex-col w-1/2 items-start">
                                <Label value="Implantat Pass" />
                            </div>
                        </div>
                        <div className="flex gap-8 mt-4">
                            <div className="flex flex-col w-1/2 items-end">
                                <ToggleSwitch
                                    checked={additionalsState.clinicalFindingsIncluded}
                                    onChange={checked =>
                                        handleChange({
                                            target: {
                                                name: 'clinicalFindingsIncluded',
                                                value: checked,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className="flex flex-col w-1/2 items-start">
                                <Label value="Klinische Befunde" />
                                {additionalsState.clinicalFindingsIncluded && (
                                    <div className="flex mt-2">
                                        <TextInput
                                            icon={InformationCircleIcon}
                                            placeholder="Anzahl, Art, etc..."
                                            value={additionalsState.clinicalFindingsIncludedNote}
                                            onChange={handleChange}
                                            name="clinicalFindingsIncludedNote"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex gap-8 mt-4">
                            <div className="flex flex-col w-1/2 items-end">
                                <ToggleSwitch
                                    checked={additionalsState.functionalFindingsIncluded}
                                    onChange={checked =>
                                        handleChange({
                                            target: {
                                                name: 'functionalFindingsIncluded',
                                                value: checked,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className="flex flex-col w-1/2 items-start">
                                <Label value="Funktionelle Befunde" />
                                {additionalsState.functionalFindingsIncluded && (
                                    <div className="flex mt-2">
                                        <TextInput
                                            icon={InformationCircleIcon}
                                            placeholder="Anzahl, Art, etc..."
                                            value={additionalsState.functionalFindingsIncludedNote}
                                            onChange={handleChange}
                                            name="functionalFindingsIncludedNote"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex gap-8 mt-4">
                            <div className="flex flex-col w-1/2 items-end">
                                <ToggleSwitch
                                    checked={additionalsState.implantPartsIncluded}
                                    onChange={checked =>
                                        handleChange({
                                            target: {
                                                name: 'implantPartsIncluded',
                                                value: checked,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className="flex flex-col w-1/2 items-start">
                                <Label value="Implantat-Teile mitgeliefert" />
                                {additionalsState.implantPartsIncluded && (
                                    <div className="flex mt-2">
                                        <TextInput
                                            icon={InformationCircleIcon}
                                            placeholder="Anzahl, Art, etc..."
                                            value={additionalsState.implantPartsIncludedNote}
                                            onChange={handleChange}
                                            name="implantPartsIncludedNote"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex gap-8 mt-4">
                            <div className="flex flex-col w-1/2 items-end">
                                <ToggleSwitch
                                    checked={additionalsState.modelsIncluded}
                                    onChange={checked =>
                                        handleChange({
                                            target: {
                                                name: 'modelsIncluded',
                                                value: checked,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className="flex flex-col w-1/2 items-start">
                                <Label value="Modelle / Situationsmodelle mitgeliefert" />
                                {additionalsState.modelsIncluded && (
                                    <div className="flex mt-2">
                                        <TextInput
                                            icon={InformationCircleIcon}
                                            placeholder="Anzahl, Art, etc..."
                                            value={additionalsState.modelsIncludedNote}
                                            onChange={handleChange}
                                            name="modelsIncludedNote"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <DefaultAlerts
                        displaySuccessMsg={displaySuccessMsg}
                        displayErrMsg={displayErrMsg}
                        errMsg={errMsg}
                        successMsg={successMsg}
                    />
                </div>
                <ContinueButtonGroup
                    onClickBack={() => navigate(-1)}
                    onClickForward={() => {
                        updateOrder();
                    }}
                />
            </div>
        </div>
    );
}
