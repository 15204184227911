import { PrinterIcon } from '@heroicons/react/24/outline';
import { FaceSmileIcon } from '@heroicons/react/24/outline';
import { Button } from 'flowbite-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useErrMsg from '../hooks/use-errormsg';
import useOrder from '../hooks/use-order';
import useUser from '../hooks/use-user';
import useAuth from '../hooks/useAuth';
import axios from '../utils/axios';
import logout from '../utils/logout';
import ContinueButtonGroup from './continue-bgroup';
import DefaultAlerts from './default-alerts';
import { decodeHTMLEntities } from './teeth-view';

export async function downloadOrderFiles(
    orderId,
    auth,
    setAuth,
    setUserState,
    setErrMsg,
    stdErrMsg,
    setDisplayErrMsg,
    setProgress
) {
    try {
        const response = await axios.get(`/order/download/${orderId}`, {
            headers: { Authorization: `Bearer ${auth?.token}` },
            responseType: 'blob',
            onUploadProgress: progressEvent => {
                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                setProgress(progress);
            },
            onDownloadProgress: progressEvent => {
                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                setProgress(progress);
            },
        });
        if (response.status === 200) {
            // setLoadedOrder(response.data);
            const href = URL.createObjectURL(response.data);
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            const filename = response.headers.get('Filename') ?? 'files.zip';
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setErrMsg(stdErrMsg);
            setProgress(0);
        }
    } catch (error) {
        // on failure: print error and display error message
        if (!error?.response) {
            setErrMsg('Server konnte nicht erreicht werden.');
        }
        if (error?.response?.status === 401) {
            setErrMsg(stdErrMsg);
            return logout(auth, setAuth, setUserState);
        }
        console.error(error);
        setDisplayErrMsg(true);
    }
}

export function WriteSimple({ name, content }) {
    return (
        content &&
        content !== '' && (
            <div className="flex flex-col items-start mt-2">
                <p className="font-bold">{name}</p>
                <p>{content}</p>
            </div>
        )
    );
}

export function WriteRestoration({ tooth, toothColor, restoration, restorationNote, isMissing }) {
    return (
        <div className="flex flex-col items-start mt-2 mr-4 mb-2">
            <p className="font-bold">{tooth}</p>
            <p>{decodeHTMLEntities(restoration)}</p>
            <p>{decodeHTMLEntities(restorationNote)}</p>
            <p>{toothColor}</p>
            <p>{isMissing ? '-' : '+'}</p>
        </div>
    );
}

export function WriteContact({ tooth, contact }) {
    return (
        <div className="flex flex-col items-start mt-2 mr-4">
            <p className="font-bold">{tooth}</p>
            <p>{contact ? '+' : '-'}</p>
        </div>
    );
}

export function WriteAppointment({ description, date, note }) {
    return (
        <div className="flex flex-col items-start mt-2 mr-4">
            <p className="font-bold">{description}</p>
            <p>{new Date(date).toLocaleString('DE-de')}</p>
            <p>{note}</p>
        </div>
    );
}

export function SingleOrderView({ orderLoaded, loadedOrder, downloadOrderFiles, progress }) {
    const [showButtons, setShowButtons] = useState(true);
    function prepareAndPrint() {
        setTimeout(() => {
            setShowButtons(true);
            window.print();
            setShowButtons(true);
        }, 100);
    }
    //TODO: add orderSeenByLab after testing
    return (
        <div className="flex flex-col gap-4 min-h-screen w-full items-start">
            {orderLoaded && Object.keys(loadedOrder).length > 4 && (
                <div className="p-8 font-mono w-full items-start">
                    <WriteSimple
                        name={'Identifikator'}
                        content={loadedOrder['orderId'] ?? loadedOrder['_id']}
                    />
                    {loadedOrder['ordered'] && (
                        <WriteSimple
                            name={'Bestellung freigegeben am'}
                            content={
                                new Date(loadedOrder['orderedAt']).toLocaleString('de') ??
                                'unbekannt'
                            }
                        />
                    )}
                    {loadedOrder['appointments'] && loadedOrder['appointments'].length > 0 && (
                        <WriteSimple
                            name={'Terminplanung erstellt'}
                            content={`${loadedOrder['appointments'].length} Termine angegeben`}
                        />
                    )}
                    {loadedOrder['offerRequestedAt'] && (
                        <WriteSimple
                            name={'KV angefragt am'}
                            content={
                                new Date(loadedOrder['offerRequestedAt']).toLocaleString('de') ??
                                'unbekannt'
                            }
                        />
                    )}
                    <WriteSimple name={'Benutzer Name'} content={loadedOrder['userName']} />
                    <WriteSimple name={'Benutzer E-Mail'} content={loadedOrder['userEmail']} />
                    <WriteSimple name={'Autor'} content={loadedOrder['author']} />
                    <WriteSimple name={'Patient'} content={loadedOrder['patientName']} />
                    <WriteSimple name={'Patienten ID'} content={loadedOrder['patientId']} />
                    <WriteSimple
                        name={'Geburtstag'}
                        content={
                            loadedOrder['patientBirthdate']
                                ? new Date(loadedOrder['patientBirthdate']).toLocaleDateString('de')
                                : ''
                        }
                    />
                    <WriteSimple
                        name={'Versicherung'}
                        content={
                            loadedOrder['insurance'] === 'PRIVATE'
                                ? 'privat'
                                : loadedOrder['insurance']
                                ? 'gesetzlich'
                                : ''
                        }
                    />
                    <WriteSimple name={'XML-Nummer'} content={loadedOrder['xmlOrderNumber']} />
                    <WriteSimple
                        name={'Abformungssystem'}
                        content={loadedOrder['impressionSystem']}
                    />
                    <WriteSimple name={'Zahnfarbe'} content={loadedOrder['outerToothColor']} />
                    <WriteSimple
                        name={'Darunterliegende Zahnfarbe'}
                        content={loadedOrder['innerToothColor']}
                    />
                    <WriteSimple name={'Notiz'} content={loadedOrder['notes']} />
                    <WriteSimple
                        name={'Ästhetische Erwartungen (von 0 bis 100)'}
                        content={loadedOrder['aestheticExpectations']}
                    />
                    <WriteSimple
                        name={'Patient kommt ins Labor'}
                        content={loadedOrder['patientComingToLab'] ? '+' : ''}
                    />
                    <WriteSimple
                        name={'Rückruf notwendig'}
                        content={loadedOrder['recallNeccessary'] ? '+' : ''}
                    />
                    <WriteSimple
                        name={'Abformung desinfiziert'}
                        content={loadedOrder['impressionDesinfected'] ? '+' : ''}
                    />
                    <div className="flex flex-col items-start mt-8">
                        <p className="font-bold">Mitgeliefert</p>
                    </div>
                    <WriteSimple
                        name={'Foto mit Zahnmusterstäbchen'}
                        content={loadedOrder['photoWithToothColorIncluded'] ? '+' : ''}
                    />
                    <WriteSimple
                        name={'Gesichtsbogen'}
                        content={loadedOrder['faceBowIncluded'] ? '+' : ''}
                    />
                    <WriteSimple
                        name={'Zentrisches Registrat'}
                        content={loadedOrder['centricRegistrationIncluded'] ? '+' : ''}
                    />
                    <WriteSimple
                        name={'Bissgabel mitgeliefert'}
                        content={loadedOrder['biteForkIncluded'] ? '+' : ''}
                    />
                    <WriteSimple
                        name={'Implantat Pass'}
                        content={loadedOrder['implantPassIncluded'] ? '+' : ''}
                    />
                    <WriteSimple
                        name={'Klinische Befunde'}
                        content={loadedOrder['clinicalFindingsIncluded'] ? '+' : ''}
                    />
                    {loadedOrder['clinicalFindingsIncluded'] && (
                        <WriteSimple
                            name={'Notiz'}
                            content={loadedOrder['clinicalFindingsIncludedNote'] ?? ''}
                        />
                    )}
                    <WriteSimple
                        name={'Funktionelle Befunde'}
                        content={loadedOrder['functionalFindingsIncluded'] ? '+' : ''}
                    />
                    {loadedOrder['functionalFindingsIncluded'] && (
                        <WriteSimple
                            name={'Notiz'}
                            content={loadedOrder['functionalFindingsIncludedNote'] ?? ''}
                        />
                    )}
                    <WriteSimple
                        name={'Implantat-Teile mitgeliefert'}
                        content={loadedOrder['implantPartsIncluded'] ? '+' : ''}
                    />
                    {loadedOrder['implantPartsIncluded'] && (
                        <WriteSimple
                            name={'Notiz'}
                            content={loadedOrder['implantPartsIncludedNote'] ?? ''}
                        />
                    )}
                    <WriteSimple
                        name={'Modelle mitgeliefert'}
                        content={loadedOrder['modelsIncluded'] ? '+' : ''}
                    />
                    {loadedOrder['modelsIncluded'] && (
                        <WriteSimple
                            name={'Notiz'}
                            content={loadedOrder['modelsIncludedNote'] ?? ''}
                        />
                    )}
                    <div className="flex flex-col items-start mt-8">
                        <p className="font-bold">Restaurationen</p>
                        <div className="flex flex-wrap">
                            {loadedOrder['restorations']
                                .toSorted((a, b) => a.tooth - b.tooth)
                                .map(r => {
                                    return (
                                        <WriteRestoration
                                            tooth={r.tooth}
                                            toothColor={r.toothColor}
                                            isMissing={r.isMissing}
                                            restoration={r.restoration}
                                            restorationNote={r.restorationNote}
                                            key={r.tooth}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                    <div className="flex flex-col items-start mt-8">
                        <p className="font-bold">Kontakte</p>
                        <div className="flex">
                            {loadedOrder['contacts'].map(c => {
                                return (
                                    <WriteContact
                                        tooth={c.tooth}
                                        contact={c.contact}
                                        key={c.tooth}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="flex flex-col items-start mt-8">
                        <p className="font-bold">Termine</p>
                        <div className="flex flex-wrap">
                            {loadedOrder['appointments'].map(a => {
                                return (
                                    <WriteAppointment
                                        description={a.description}
                                        date={a.date}
                                        note={a.note}
                                        key={a.date}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="flex flex-col items-start mt-8">
                        <p className="font-bold">Dateien</p>
                        <div className="flex flex-col items-start mt-2 mr-4">
                            {loadedOrder['files'].map(f => {
                                return <p key={f.name}>{f.name}</p>;
                            })}
                        </div>
                    </div>
                    <WriteSimple name={'Notiz zu Dateien'} content={loadedOrder['fileNote']} />
                    {showButtons && (
                        <>
                            <div
                                className="flex flex-col items-start mt-4 hover:cursor-pointer"
                                onClick={downloadOrderFiles}
                            >
                                <p className="font-bold underline">[Dateien herunterladen]</p>
                            </div>
                            {progress > 0 && (
                                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                    <div
                                        className="bg-blue-600 h-2.5 rounded-full"
                                        style={{ width: progress + '%' }}
                                    ></div>
                                </div>
                            )}
                            <div className="flex flex-row justify-end items-center mt-4 hover:cursor-pointer">
                                <PrinterIcon className="w-20" onClick={() => prepareAndPrint()} />
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default function OrderViewSingle() {
    const [displayThanks, setDisplayThanks] = useState(false);
    const [displayErrMsg, setDisplayErrMsg] = useState(false);
    const [displaySuccessMsg, setDisplaySuccessMsg] = useState(false);
    const { errMsg, setErrMsg, successMsg, setSuccessMsg, stdErrMsg, stdSuccessMsg } = useErrMsg();
    const { auth, setAuth } = useAuth();
    const { setUserState } = useUser();
    const [loadedOrder, setLoadedOrder] = useState({
        restorations: [],
        contacts: [],
        appointments: [],
        files: [],
    });
    const [orderLoaded, setOrderLoaded] = useState(false);
    const navigate = useNavigate();
    const { activeOrder } = useOrder();
    const orderId = activeOrder.orderId;
    const [progress, setProgress] = useState(0);

    const getOrder = useCallback(
        async function getOrder(orderId) {
            try {
                const response = await axios.get(`/order/${orderId}`, {
                    headers: { Authorization: `Bearer ${auth?.token}` },
                });
                if (response.status === 200) {
                    setLoadedOrder(response.data);
                    setErrMsg(stdErrMsg);
                }
            } catch (error) {
                // on failure: print error and display error message
                if (!error?.response) {
                    setErrMsg('Server konnte nicht erreicht werden.');
                }
                if (error?.response?.status === 401) {
                    setErrMsg(stdErrMsg);
                    return logout(auth, setAuth, setUserState);
                }
                console.error(error);
                setDisplayErrMsg(true);
            }
        },
        [auth, setAuth, setErrMsg, stdErrMsg, setUserState]
    );

    useEffect(() => {
        if (!orderLoaded) {
            getOrder(orderId);
            setOrderLoaded(true);
        }
    }, [orderLoaded, getOrder, orderId, loadedOrder]);

    async function releaseOrder({ ordered, appointmentsOnly, filesOnly, offerRequested }) {
        try {
            const body = {};
            if (ordered) body.ordered = true;
            if (appointmentsOnly) {
                if (loadedOrder.appointments && loadedOrder.appointments.length < 1) {
                    setErrMsg('Keine Termine angegeben!');
                    setDisplayErrMsg(true);
                    return setTimeout(() => {
                        setErrMsg(stdErrMsg);
                        setDisplayErrMsg(false);
                    }, 5000);
                }
                setSuccessMsg('Termine übertragen! Leite zur Startseite weiter ...');
                setDisplaySuccessMsg(true);
                return setTimeout(() => {
                    setSuccessMsg(stdSuccessMsg);
                    setDisplaySuccessMsg(false);
                    navigate('/');
                }, 5000);
            }
            if (filesOnly) {
                if (loadedOrder.files && loadedOrder.files.length < 1) {
                    setErrMsg('Keine Dateien hochgeladen!');
                    setDisplayErrMsg(true);
                    return setTimeout(() => {
                        setErrMsg(stdErrMsg);
                        setDisplayErrMsg(false);
                    }, 5000);
                }
                setSuccessMsg('Dateien hochgeladen! Leite zur Startseite weiter ...');
                setDisplaySuccessMsg(true);
                return setTimeout(() => {
                    setSuccessMsg(stdSuccessMsg);
                    setDisplaySuccessMsg(false);
                    navigate('/');
                }, 5000);
            }
            if (offerRequested) body.offerRequestedAt = true;
            let response;
            response = await axios.post(
                '/order/addAdditionalInformation',
                { orderId, ...body },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth?.token}`,
                    },
                }
            );
            if (response.status === 200) {
                setSuccessMsg(
                    `Erfolgreich ${
                        body.ordered ? 'freigegeben' : ''
                    }! Leite zur Startseite weiter ...`
                );
                setDisplaySuccessMsg(true);
                setDisplayThanks(true);
                setErrMsg(stdErrMsg);
                return setTimeout(() => {
                    setSuccessMsg(stdSuccessMsg);
                    navigate('/');
                }, 5000);
            }
        } catch (error) {
            // on failure: print error and display error message
            if (!error?.response) {
                setErrMsg('Server konnte nicht erreicht werden.');
            }
            if (error?.response?.status === 401) {
                setErrMsg(stdErrMsg);
                return logout(auth, setAuth, setUserState);
            }
            console.error(error);
            setDisplayErrMsg(true);
        }
    }

    return (
        <div className="flex flex-col gap-4 min-h-screen items-center">
            <div>
                <h1>Auftragsübersicht</h1>
            </div>
            <SingleOrderView
                orderLoaded={orderLoaded}
                loadedOrder={loadedOrder}
                progress={progress}
                downloadOrderFiles={() =>
                    downloadOrderFiles(
                        orderId,
                        auth,
                        setAuth,
                        setUserState,
                        setErrMsg,
                        stdErrMsg,
                        setDisplayErrMsg,
                        setProgress
                    )
                }
            />
            <DefaultAlerts
                displaySuccessMsg={displaySuccessMsg}
                displayErrMsg={displayErrMsg}
                errMsg={errMsg}
                successMsg={successMsg}
            />
            {displayThanks && (
                <div className="flex flex-col items-center">
                    <FaceSmileIcon className="text-green-500 w-24" />
                    <p className="font-bold"> ihr Dentallabor bedankt sich! </p>
                </div>
            )}
            <div className="flex-col justify-end w-full p-10 pt-0 pb-0">
                <div className="flex justify-end">
                    <Button
                        onClick={() => {
                            releaseOrder({ filesOnly: true });
                        }}
                        outline={true}
                        gradientDuoTone="cyanToBlue"
                    >
                        Nur Dateien übertragen
                    </Button>
                </div>
                <div className="flex justify-end pt-2">
                    <Button
                        onClick={() => {
                            releaseOrder({ offerRequested: true });
                        }}
                        outline={true}
                        gradientDuoTone="tealToLime"
                    >
                        KV Anfrage senden
                    </Button>
                </div>
                <div className="flex justify-end pt-2">
                    <Button
                        onClick={() => {
                            releaseOrder({ appointmentsOnly: true });
                        }}
                        outline={true}
                        gradientDuoTone="greenToBlue"
                    >
                        Terminplanung senden
                    </Button>
                </div>
            </div>
            <ContinueButtonGroup
                onClickBack={() => navigate(-1)}
                onClickForward={() => {
                    releaseOrder({ ordered: true });
                }}
                forwardButtonText="Auftrag freigeben"
            />
        </div>
    );
}
