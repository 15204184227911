import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import StarterView from './starter-view';
import LoginView from './login-view';
import ExampleViewComponent from './example-view-component';
import TopNavbar from './navbar';
import BottomFooter from './bottom-footer';
import RequireAuth from './require-auth';
import { AuthProvider } from './context/AuthContext';
import OrderView from './orderview';
import NewOrder from './neworder';
import EditUser from './edit-user';
import TeethView from './teeth-view';
import { UserProvider } from './context/user-context';
import AppointmentsView from './appointments-view';
import FileUpload from './file-upload';
import { ErrorMsgProvider } from './context/errormsg-context';
import { OrderProvider } from './context/order-context';
import AdditionalsView from './additionals-view';
import OrderViewSingle from './order-view-single';
import LabView from './lab-view';
import RequireRole, { USER_ROLE } from './require-role';
import HomeView from './home-view';
import LabOrderViewSingle from './lab-order-view-single';
import LegendView from './legend-view';
import HelpView from './help-view';
import TechnicalDetailsView from './technical-details-view';

export default function AppRouter() {
    return (
        <Router>
            <AuthProvider>
                <UserProvider>
                    <OrderProvider>
                        <ErrorMsgProvider>
                            <TopNavbar />
                            <Routes>
                                {/* protected routes */}
                                <Route element={<RequireAuth />}>
                                    <Route element={<RequireRole role={USER_ROLE.DOC} />}>
                                        {/* doc routes */}
                                        <Route path="/" element={<StarterView />} />
                                        <Route path="/orderview" element={<OrderView />} />
                                        <Route path="/neworder" element={<NewOrder />} />
                                        <Route path="/additionals" element={<AdditionalsView />} />
                                        <Route path="/teethView" element={<TeethView />} />
                                        <Route
                                            path="/appointments"
                                            element={<AppointmentsView />}
                                        />
                                        <Route path="/fileUpload" element={<FileUpload />} />
                                        <Route
                                            path="/orderViewSingle"
                                            element={<OrderViewSingle />}
                                        />
                                    </Route>
                                    <Route element={<RequireRole role={USER_ROLE.LAB} />}>
                                        {/* lab routes */}
                                        <Route path="/lab/" element={<LabView />} />
                                        <Route
                                            path="/lab/orderViewSingle"
                                            element={<LabOrderViewSingle />}
                                        />
                                    </Route>
                                    <Route path="/editUser" element={<EditUser />} />
                                    <Route path="/home" element={<HomeView />} />
                                    <Route path="/legend" element={<LegendView />} />
                                    <Route path="/help" element={<HelpView />} />
                                    <Route path="/technicalDetails" element={<TechnicalDetailsView />} />
                                    <Route path="/whatever" element={<ExampleViewComponent />} />
                                </Route>
                                {/* unprotected routes */}
                                <Route path="/login" element={<LoginView />} />
                                <Route path="*" element={<Navigate to="/home" />} />
                            </Routes>
                            <BottomFooter />
                        </ErrorMsgProvider>
                    </OrderProvider>
                </UserProvider>
            </AuthProvider>
        </Router>
    );
}
