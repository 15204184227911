import { XMarkIcon } from '@heroicons/react/24/solid';
import { Alert } from 'flowbite-react';

export default function DefaultAlerts({ displayErrMsg, errMsg, displaySuccessMsg, successMsg }) {
    return (
        <>
            {displayErrMsg && (
                <Alert color="failure" icon={XMarkIcon}>
                    <span>
                        <p>{errMsg ?? 'Da ist etwas schief gelaufen! Bitten den Fehler melden. :-)'}</p>
                    </span>
                </Alert>
            )}
            {displaySuccessMsg && (
                <Alert color="success">
                    <span>
                        <p>{successMsg ?? 'Erfolgreich gespeichert! Weiter gehts!'}</p>
                    </span>
                </Alert>
            )}
        </>
    );
}
