import { Table } from 'flowbite-react';
import { TableBody } from 'flowbite-react/lib/esm/components/Table/TableBody';
import { TableCell } from 'flowbite-react/lib/esm/components/Table/TableCell';
import { TableHead } from 'flowbite-react/lib/esm/components/Table/TableHead';
import { TableHeadCell } from 'flowbite-react/lib/esm/components/Table/TableHeadCell';
import { TableRow } from 'flowbite-react/lib/esm/components/Table/TableRow';
import { useEffect } from 'react';

const helpAndTips = [
    {
        title: 'KV Anfrage, Terminplanung, Dateiübertragung, Auftragserstellung',
        tip: 'In jedem Fall kann ein Auftrag regulär angelegt werden. Am Ende, in der Auftragsübersicht, wird gewählt welche Art Informationen übermittelt werden sollen.',
    },
    {
        title: 'Muss schnell gehen!',
        tip: 'Sämtliche Schritte während der Auftragsanlage können übersprungen werden. Es gilt jedoch: Wissen ist Macht. Einem reibungslosen Ablauf zugunsten sollte das Labor möglichst ermächtigt werden die Anfrage zu bearbeiten.',
    },
    {
        title: 'Auftragsanlage',
        tip: 'Aufträge können nach und nach bearbeitet werden. So lässt sich beispielsweise zu Beginn ein Auftrag mit den benötigten Informationen für einen KV anlegen (Stufe 1). Sobald der KV genehmig ist, kann dieser Auftrag in der Auftragsübersicht erneut ausgewählt werden und die Terminplanung lässt sich eintragen (Stufe 2). Zum Präparationstermin kann der Auftrag erneut aufgerufen, mit zusätzlichen Informationen und Dateien ergänzt und zur Fertigung freigegeben werden (Stufe 3). Sobald der Auftrag zur Fertigung freigegeben wird, werden die übertragenen Informationen fixiert und es können nun mehr lediglich zusätzliche Termine und Dateien hinzugefügt werden.',
    },
    {
        title: 'Auftragsnummern',
        tip: 'Auftragsnummern zur Generierung einer XML-Datei können beispielsweise aus der Praxis Software kopiert oder per Foto/Screenshot im Datei-Upload übertragen werden.',
    },
    {
        title: 'Dokumente / Datei Upload',
        tip: 'Falls nur Dokumente oder Dateien hochgeladen werden, empfiehlt es sich eine Notiz zur Beschreibung der Daten beim Dateiupload zu hinterlassen.',
    },
    {
        title: 'Sprachaufnahme',
        tip: 'Ein schneller Tipper schafft durchschnittlich 40 Worte pro Minute¹ während Tonbücher bei entspannten 160 Worten pro Minute gesprochen werden². Stress in der Praxis? Sprachaufnahme erstellen und Zeit sparen!',
    },
    {
        title: '',
        tip: (
            <div>
                <p>
                    ¹: Karat CM, Halverson C, Horn D, Karat J (1999). "Patterns of entry and
                    correction in large vocabulary continuous speech recognition systems".
                    Proceedings of the SIGCHI conference on Human Factors in Computing Systems (CHI
                    '99). New York, NY, US: ACM. pp. 568–575. doi:10.1145/302979.303160. ISBN
                    0-201-48559-1.
                </p>
                <p>
                    ²: Williams, J. R. (1998). Guidelines for the use of multimedia in instruction,
                    Proceedings of the Human Factors and Ergonomics Society 42nd Annual Meeting,
                    1447–1451
                </p>
            </div>
        ),
    },
];

export default function HelpView() {
    useEffect(() => window.scrollTo(0, 0));
    return (
        <div className="flex flex-col gap-4 items-center min-h-screen">
            <h1> Tipps & Tricks </h1>
            <div className="overflow-x-scroll justify-center w-full p-1">
                <Table hoverable>
                    <TableHead>
                        <TableHeadCell>Thema</TableHeadCell>
                        <TableHeadCell>Tip</TableHeadCell>
                    </TableHead>
                    <TableBody className="bg-white divide-y">
                        {helpAndTips.map((r, i) => {
                            return (
                                <TableRow key={`row-${i}`}>
                                    <TableCell key={`${r.title}`}>{r.title}</TableCell>
                                    <TableCell key={`${r.tip}`}>{r.tip}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}
