import { Checkbox, Table, ToggleSwitch, Button } from 'flowbite-react';
import { useEffect, useState } from 'react';
import ToothModal from './tooth-modal';
import axios from '../utils/axios';
import useAuth from '../hooks/useAuth';
import logout from '../utils/logout';
import { useNavigate } from 'react-router-dom';
import ContinueButtonGroup from './continue-bgroup';
import DefaultAlerts from './default-alerts';
import useErrMsg from '../hooks/use-errormsg';
import useOrder from '../hooks/use-order';
import useUser from '../hooks/use-user';

export function decodeHTMLEntities(text) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
}

export default function TeethView() {
    const { activeOrder, setActiveOrder } = useOrder();
    const orderId = activeOrder.orderId;

    const [displayErrMsg, setDisplayErrMsg] = useState(false);
    const [displaySuccessMsg, setDisplaySuccessMsg] = useState(false);
    const [shimstockAlert, setShimstockAlert] = useState(true);

    const { errMsg, setErrMsg, successMsg, stdErrMsg } = useErrMsg();
    const { auth, setAuth } = useAuth();
    const { setUserState } = useUser();

    const navigate = useNavigate();

    const [contactState, setContactState] = useState({
        11: false,
        12: false,
        13: false,
        14: false,
        15: false,
        16: false,
        17: false,
        18: false,
        21: false,
        22: false,
        23: false,
        24: false,
        25: false,
        26: false,
        27: false,
        28: false,
    });

    const [restorations, setRestorations] = useState([]);

    useEffect(() => {
        if (activeOrder.contacts?.length > 0) {
            setContactState({});
            const temp = {};
            activeOrder.contacts.forEach(c => {
                temp[c.tooth] = c.contact;
            });
            setContactState(temp);
        }
        if (activeOrder.restorations?.length > 0) {
            setRestorations([]);
            const restorationsTemp = [];
            activeOrder.restorations.forEach(r => {
                restorationsTemp.push({
                    isMissing: r.isMissing,
                    restoration: decodeHTMLEntities(r.restoration),
                    restorationMaterial: r.restorationMaterial,
                    restorationNote: r.restorationNote,
                    tooth: r.tooth,
                    toothColor: r.toothColor,
                });
            });
            setRestorations(restorationsTemp);
        }
    }, [activeOrder]);

    async function updateOrder() {
        if (
            shimstockAlert &&
            !Object.values(contactState).includes(true) &&
            restorations.length < 24 &&
            restorations.length > 0
        ) {
            setErrMsg('Ohne Shimstockprotokoll fortfahren?');
            setDisplayErrMsg(true);
            setShimstockAlert(false);
            return;
        }
        setDisplayErrMsg(false);
        setErrMsg(stdErrMsg);
        try {
            let response;
            let changed = false;
            if (restorations.length > 0) changed = true;
            const array = Object.keys(contactState).map(key => {
                if (contactState[key]) changed = true;
                return { tooth: key, contact: contactState[key] };
            });
            if (!changed) {
                setDisplaySuccessMsg(true);
                return setTimeout(() => navigate('/additionals'), 500);
            }
            setDisplaySuccessMsg(true);
            response = await axios.post(
                '/order/addContacts',
                { orderId, contacts: array },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth?.token}`,
                    },
                }
            );
            //do those requests synchronously to avoid version errors on save
            for (const rest of restorations) {
                response = await axios.post(
                    '/order/addRestoration',
                    { orderId, ...rest },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${auth?.token}`,
                        },
                    }
                );
            }
            // await Promise.all(
            //     restorations.map(async restoration => {
            //         response = await axios.post(
            //             '/order/addRestoration',
            //             { orderId, ...restoration },
            //             {
            //                 headers: {
            //                     'Content-Type': 'application/json',
            //                     Authorization: `Bearer ${auth?.token}`,
            //                 },
            //             }
            //         );
            //     })
            // );
            if (response.status === 200) {
                const { _id, ...body } = response.data;
                setActiveOrder({ ...body, orderId: _id });
                setErrMsg(stdErrMsg);
                return setTimeout(() => navigate('/additionals'), 500);
            }
        } catch (error) {
            setDisplaySuccessMsg(false);
            // on failure: print error and display error message
            if (!error?.response) {
                setErrMsg('Server konnte nicht erreicht werden.');
            }
            if (error?.response?.status === 401) {
                setErrMsg(stdErrMsg);
                return logout(auth, setAuth, setUserState);
            }
            console.error(error);
            setDisplayErrMsg(true);
        }
    }

    function handleContactChange(event) {
        const name = event.target.name.replace('Checkbox', '');
        const value = event.target.checked;
        setContactState({ ...contactState, [name]: value });
    }

    const [multiSelect, setMultiSelect] = useState(false);
    function handleMultiSelect(checked) {
        setMultiSelect(checked);
        !checked && setOpenModal({ open: false, teeth: [] });
        return;
    }
    const [openModal, setOpenModal] = useState({ open: false, teeth: [] });
    function openToothModal(tooth) {
        if (multiSelect) {
            const index = openModal.teeth.indexOf(tooth);
            index !== -1 ? openModal.teeth.splice(index, 1) : openModal.teeth.push(tooth);
            setOpenModal({ open: false, teeth: openModal.teeth });
        } else {
            openModal.teeth.push(tooth);
            setOpenModal({ open: true, teeth: openModal.teeth });
        }
    }

    const teethArray4 = ['41', '42', '43', '44', '45', '46', '47', '48'];
    const teethArray3 = ['31', '32', '33', '34', '35', '36', '37', '38'];
    const teethArray2 = ['21', '22', '23', '24', '25', '26', '27', '28'];
    const teethArray1 = ['11', '12', '13', '14', '15', '16', '17', '18'];
    function myHeadCell(tooth) {
        const className = 'w-20 text-center bg-white';
        return (
            <Table.HeadCell key={`HeadCell${tooth}`} id={`HeadCell${tooth}`} className={className}>
                {tooth}
            </Table.HeadCell>
        );
    }
    function myToothCell(tooth) {
        const restoration = restorations.find(r => r.tooth === tooth);
        const selectedBg = openModal.teeth.includes(tooth) ? 'bg-blue-400' : '';
        const missingBg = restoration?.isMissing && selectedBg === '' ? 'bg-red-100' : '';
        const className = `border border-blue-500 rounded-md whitespace-nowrap font-medium text-gray-900 dark:text-white overflow-x-clip text-center hover:bg-cyan-500 ${selectedBg} ${missingBg}`;
        return restoration ? (
            <Table.Cell
                key={`ToothCell${tooth}`}
                id={`ToothCell${tooth}`}
                onClick={() => openToothModal(tooth)}
                className={className}
            >
                {`${restoration.restoration}`}
            </Table.Cell>
        ) : (
            <Table.Cell
                key={`ToothCell${tooth}`}
                id={`ToothCell${tooth}`}
                onClick={() => openToothModal(tooth)}
                className={className}
            >
                <p className="invisible">-</p>
            </Table.Cell>
        );
    }
    function myContactCell(tooth) {
        const className = 'w-max text-center bg-white';
        return (
            <Table.Cell
                key={`CheckboxCell${tooth}`}
                id={`CheckboxCell${tooth}`}
                className={className}
            >
                <Checkbox
                    key={`Checkbox${tooth}`}
                    id={`Checkbox${tooth}`}
                    name={`Checkbox${tooth}`}
                    checked={contactState[tooth]}
                    onChange={handleContactChange}
                    className="hover:bg-blue-500"
                />
            </Table.Cell>
        );
    }

    return (
        <div key="teethView0" className="relative flex flex-col gap-4 items-center min-h-screen ">
            <h1>Geplante Restorationen und derzeitiger Status</h1>
            <ToothModal
                restorations={restorations}
                openModal={openModal}
                setOpenModal={setOpenModal}
                key="toothModal0"
            />
            <div className="flex flex-row w-full shadow overflow-x-scroll bg-white mt-10">
                <Table className="table-fixed border-separate bg-white border border-gray-200 rounded-lg">
                    <Table.Head className="bg-white">
                        {teethArray1.reverse().map(tooth => {
                            return myHeadCell(tooth);
                        })}
                        {teethArray2.map(tooth => {
                            return myHeadCell(tooth);
                        })}
                    </Table.Head>
                    <Table.Body>
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            {/*array is reversed*/}
                            {teethArray1.map(tooth => {
                                return myToothCell(tooth);
                            })}
                            {teethArray2.map(tooth => {
                                return myToothCell(tooth);
                            })}
                        </Table.Row>
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            {/*array is reversed*/}
                            {teethArray1.map(tooth => {
                                return myContactCell(tooth);
                            })}
                            {teethArray2.map(tooth => {
                                return myContactCell(tooth);
                            })}
                        </Table.Row>
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            {teethArray4.reverse().map(tooth => {
                                return myToothCell(tooth);
                            })}
                            {teethArray3.map(tooth => {
                                return myToothCell(tooth);
                            })}
                        </Table.Row>
                    </Table.Body>
                    <Table.Head>
                        {/*array is reversed*/}
                        {teethArray4.map(tooth => {
                            return myHeadCell(tooth);
                        })}
                        {teethArray3.map(tooth => {
                            return myHeadCell(tooth);
                        })}
                    </Table.Head>
                </Table>
            </div>
            <div className="flex flex-col gap-8 p-4">
                <ToggleSwitch
                    checked={multiSelect}
                    label="Multi-Auswahl aktivieren"
                    onChange={checked => handleMultiSelect(checked)}
                />
                {multiSelect && (
                    <Button
                        outline={true}
                        gradientDuoTone="cyanToBlue"
                        onClick={() => {
                            setMultiSelect(false);
                            setOpenModal({ open: true, teeth: openModal.teeth });
                        }}
                    >
                        Für Auswahl festlegen
                    </Button>
                )}
            </div>
            <ContinueButtonGroup
                onClickForward={() => updateOrder()}
                onClickBack={() => navigate('/neworder')}
            />
            <div>
                <DefaultAlerts
                    displaySuccessMsg={displaySuccessMsg}
                    displayErrMsg={displayErrMsg}
                    errMsg={errMsg}
                    successMsg={successMsg}
                />
            </div>
        </div>
    );
}
