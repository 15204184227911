import { Footer } from 'flowbite-react';
import { SparklesIcon } from '@heroicons/react/24/solid';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

export default function BottomFooter() {
    const { auth } = useAuth();
    const navigate = useNavigate();
    if (auth?.token) {
        return (
            <Footer container>
                <div className="w-full text-center">
                    <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
                        <Footer.Brand onClick={() => navigate('/')}>
                            <SparklesIcon className="mr-3 h-9 sm:h-9 text-blue-500" />
                            <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                                Digitales Auftragsformular
                            </span>
                        </Footer.Brand>
                        <Footer.LinkGroup>
                            <Footer.Link
                                className="cursor-pointer pr-10"
                                onClick={() => navigate('/technicalDetails')}
                            >
                                Technische Details & Datenschutz
                            </Footer.Link>
                            <Footer.Link
                                className="cursor-pointer pr-10"
                                onClick={() => navigate('/help')}
                            >
                                Hilfe
                            </Footer.Link>
                            <Footer.Link
                                className="cursor-pointer pr-10"
                                onClick={() => navigate('/legend')}
                            >
                                Legende
                            </Footer.Link>
                            <Footer.Link
                                className="cursor-pointer pr-10"
                                onClick={() => navigate('/whatever')}
                            >
                                About
                            </Footer.Link>
                            {/* <Footer.Link onClick={() => navigate('/')}>Privacy Policy</Footer.Link>
                             <Footer.Link onClick={() => navigate('/')}>Licensing</Footer.Link>
                             <Footer.Link onClick={() => navigate('/')}>Contact</Footer.Link>*/}
                        </Footer.LinkGroup>
                    </div>
                    <Footer.Divider />
                    <Footer.Copyright className="hover:cursor-pointer" by="DAF" onClick={() => navigate('/')} year={2023} />
                </div>
            </Footer>
        );
    }
}
