import { createContext, useState } from 'react';

const AuthContext = createContext({});

export function AuthProvider({ children }) {
    const token = sessionStorage.getItem('token');
    const [auth, setAuth] = useState({ token: token });
    return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>;
}

export default AuthContext;
