import { createContext, useState } from 'react';
import useOrder from '../../hooks/use-order';

const ErrorMsgContext = createContext({});

export function ErrorMsgProvider({ children }) {
    const {activeOrder} = useOrder()
    let stdErrMsg = `Eingaben ungültig! Bitte erneut versuchen. ${activeOrder.orderId === 'undefined' ? 'Wurde ein Auftrag ausgewählt oder neu angelegt?' : ''}`;
    if(activeOrder.ordered) stdErrMsg = 'Bei bereits freigegebenen Aufträgen können nur noch Termine und Dateien hinzugefügt werden!'
    const stdSuccessMsg = 'Super! Weiter gehts!';
    const [errMsg, setErrMsg] = useState(stdErrMsg);
    const [successMsg, setSuccessMsg] = useState('Super! Weiter gehts!');
    return (
        <ErrorMsgContext.Provider
            value={{
                errMsg,
                setErrMsg,
                successMsg,
                setSuccessMsg,
                stdErrMsg,
                stdSuccessMsg,
            }}
        >
            {children}
        </ErrorMsgContext.Provider>
    );
}

export default ErrorMsgContext;
