import { useState } from 'react';
import useErrMsg from '../hooks/use-errormsg';
import useOrder from '../hooks/use-order';
import useUser from '../hooks/use-user';
import useAuth from '../hooks/useAuth';
import DefaultAlerts from './default-alerts';
import { downloadOrderFiles, SingleOrderView } from './order-view-single';

export default function LabOrderViewSingle() {
    //use download function
    const { activeOrder } = useOrder();
    const { auth, setAuth } = useAuth();
    const { setUserState } = useUser();
    const { errMsg, setErrMsg, stdErrMsg, successMsg } = useErrMsg();
    const [displayErrMsg, setDisplayErrMsg] = useState(false);
    const [displaySuccessMsg] = useState(false);
    const [progress, setProgress] = useState(0);
    return (
        <div>
            <SingleOrderView
                orderLoaded={true}
                loadedOrder={activeOrder}
                progress={progress}
                downloadOrderFiles={() =>
                    downloadOrderFiles(
                        activeOrder.orderId ?? activeOrder._id,
                        auth,
                        setAuth,
                        setUserState,
                        setErrMsg,
                        stdErrMsg,
                        setDisplayErrMsg,
                        setProgress
                    )
                }
            />

            <div className="mt-8">
                <DefaultAlerts
                    displaySuccessMsg={displaySuccessMsg}
                    displayErrMsg={displayErrMsg}
                    errMsg={errMsg}
                    successMsg={successMsg}
                />
            </div>
        </div>
    );
}
