import { EnvelopeIcon, EyeIcon, KeyIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { TextInput, Label, Checkbox, Button, Alert } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import { useState } from 'react';
import useAuth from '../hooks/useAuth';
import useErrMsg from '../hooks/use-errormsg';
import useUser from '../hooks/use-user';
import { useEffect } from 'react';
import logout from '../utils/logout';

export default function LoginForm({ fadeDuration, toggleTransition }) {
    const [displayErrMsg, setDisplayErrMsg] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const { errMsg, setErrMsg, stdErrMsg } = useErrMsg();
    const [showPassword, setShowPassword] = useState(false);

    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();
    const { userState, setUserState } = useUser();
    const goTo = '/home';

    useEffect(() => {
        //LOG OUT WHEN LOGGED IN
        if (((userState.email !== '' && userState.name !== '') || auth.token) && !loggedIn)
            logout(auth, setAuth, setUserState);
    });

    async function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());

        try {
            // try getting jwt token
            const response = await axios.post('/login', JSON.stringify(formJson), {
                headers: { 'Content-Type': 'application/json' },
            });
            // on success: save token in context and redirect
            if (response.status === 200) {
                setAuth({ token: response?.data?.token });
                if (formJson.remember) {
                    try {
                        sessionStorage.setItem('token', response?.data?.token);
                    } catch (error) {
                        console.error(error.message);
                    }
                }
                toggleTransition();
                setErrMsg(stdErrMsg);
                setLoggedIn(true);
                setTimeout(() => {
                    navigate(goTo);
                }, fadeDuration + 100);
            }
        } catch (error) {
            // on failure: print error and display error message
            if (!error?.response) {
                setErrMsg('Server konnte nicht erreicht werden.');
            }
            console.error(error);
            setDisplayErrMsg(true);
            sessionStorage.removeItem('token');
            setErrMsg(stdErrMsg);
            return navigate('/login');
        }
    }

    return (
        <div>
            <form method="post" className="flex flex-col gap-4" onSubmit={handleSubmit}>
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="email1" value="E-Mail" />
                    </div>
                    <TextInput
                        icon={EnvelopeIcon}
                        id="email1"
                        name="email"
                        type="email"
                        placeholder="name@domain.de"
                        required={true}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="password1" value="Passwort" />
                    </div>
                    <div className="flex justify-center w-full relative">
                        <TextInput
                            icon={KeyIcon}
                            id="password1"
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            required={true}
                            className="w-full"
                        />
                        <EyeIcon
                            className="hover:cursor-pointer w-6 ml-2 overflow-visible text-gray-700 absolute right-2.5 top-2.5"
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </div>
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="remember" name="remember" type="checkbox" />
                    <Label htmlFor="remember">
                        Diesem Gerät vertrauen und heute eingeloggt bleiben
                    </Label>
                </div>
                <Button outline={true} gradientDuoTone="cyanToBlue" type="submit">
                    Absenden
                </Button>
                {displayErrMsg && (
                    <Alert color="failure" icon={XMarkIcon}>
                        <span>
                            <p>{errMsg}</p>
                        </span>
                    </Alert>
                )}
            </form>
        </div>
    );
}
