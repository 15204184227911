import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { TextInput, Label, Checkbox } from 'flowbite-react';
import { UserIcon, IdentificationIcon } from '@heroicons/react/24/solid';
import { FaceSmileIcon } from '@heroicons/react/24/outline';
import useOrder from '../hooks/use-order';
import { useEffect, useState } from 'react';
import useErrMsg from '../hooks/use-errormsg';
import DefaultAlerts from './default-alerts';
import ContinueButtonGroup from './continue-bgroup';
import axios from '../utils/axios';
import logout from '../utils/logout';
import {
    CustomDatepickerInput,
    datePickerClassName,
    datePickerInputClassName,
} from './appointments-view';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import useUser from '../hooks/use-user';
//used for the datepicker
registerLocale('de', de);

export default function NewOrder() {
    const [displayErrMsg, setDisplayErrMsg] = useState(false);
    const [displaySuccessMsg, setDisplaySuccessMsg] = useState(false);
    const { errMsg, setErrMsg, successMsg, stdErrMsg } = useErrMsg();

    const { activeOrder, setActiveOrder } = useOrder();
    const orderId = activeOrder.orderId;

    const [newOrderState, setNewOrderState] = useState({
        patientName: activeOrder.patientName ?? '',
        patientId: activeOrder.patientId ?? '',
        insurance: activeOrder.insurance ?? 'PRIVATE',
        author: activeOrder.author ?? '',
        patientBirthdate: Date.parse(activeOrder.patientBirthdate ?? new Date()),
    });

    useEffect(() => {
        setNewOrderState({
            patientName: activeOrder.patientName ?? '',
            patientId: activeOrder.patientId ?? '',
            insurance: activeOrder.insurance ?? 'PRIVATE',
            author: activeOrder.author ?? '',
            patientBirthdate: Date.parse(activeOrder.patientBirthdate ?? new Date()),
        });
    }, [activeOrder]);

    function handleChange(event) {
        if (event.target) {
            const name = event.target.name;
            const value =
                event.target.type === 'checkbox' ? event.target.checked : event.target.value;
            setNewOrderState({
                ...newOrderState,
                [name]: value,
            });
        } else if (event.insurance) {
            setNewOrderState({
                ...newOrderState,
                insurance: event.insurance,
            });
        }
    }

    const { auth, setAuth } = useAuth();
    const { setUserState } = useUser();
    const navigate = useNavigate();

    async function updateOrder() {
        if (newOrderState.patientName === '' && newOrderState.patientId === '') {
            setDisplaySuccessMsg(false);
            setErrMsg('Bitte mindestens einen Identifikator (Patienten-ID oder -Name) angeben :-)');
            setDisplayErrMsg(true);
            return;
        }
        setDisplayErrMsg(false);
        setErrMsg(stdErrMsg);
        try {
            let response;
            if (!orderId || orderId === 'undefined') {
                response = await axios.post(
                    '/order/createOrder',
                    { ...newOrderState },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${auth?.token}`,
                        },
                    }
                );
            } else {
                response = await axios.patch(
                    '/order/updateCreateOrder',
                    { orderId: orderId, ...newOrderState },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${auth?.token}`,
                        },
                    }
                );
            }
            if (response.status === 200) {
                setDisplaySuccessMsg(true);
                setErrMsg(stdErrMsg);
                const { _id, ...body } = response.data;
                setActiveOrder({ ...body, orderId: _id });
                try {
                    localStorage.setItem('orderId', response.data._id);
                } catch (error) {
                    console.error(error.message);
                }
                return setTimeout(() => navigate('/teethView'), 500);
            }
        } catch (error) {
            // on failure: print error and display error message
            if (!error?.response) {
                setErrMsg('Server konnte nicht erreicht werden.');
            }
            if (error?.response?.status === 401) {
                setErrMsg(stdErrMsg);
                return logout(auth, setAuth, setUserState);
            }
            console.error(error);
            setDisplayErrMsg(true);
        }
    }

    return (
        <div className="flex flex-col gap-4 min-h-screen items-center">
            <div className="lg:w-3/4 w-full">
                <h1 className="pb-4">Auftrag anlegen</h1>
                <div className="flex flex-col gap-4">
                    <div>
                        <div className="mb-2 block">
                            <Label value="Autor des Auftrags" />
                        </div>
                        <TextInput
                            icon={FaceSmileIcon}
                            placeholder="Sabine Sicher"
                            value={newOrderState.author}
                            onChange={handleChange}
                            name="author"
                        />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label value="Patient" />
                        </div>
                        <TextInput
                            icon={UserIcon}
                            placeholder="Max Mustermann"
                            value={newOrderState.patientName}
                            onChange={handleChange}
                            name="patientName"
                        />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label value="Patienten ID / Paginiernummer" />
                        </div>
                        <TextInput
                            icon={IdentificationIcon}
                            placeholder="ID-00-ab"
                            value={newOrderState.patientId}
                            onChange={handleChange}
                            name="patientId"
                        />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label value="Geburtsdatum" />
                        </div>
                        <div className={datePickerClassName}>
                            <div className="ml-1">
                                <DatePicker
                                    locale="de"
                                    selected={newOrderState.patientBirthdate ?? new Date()}
                                    onChange={date => {
                                        setNewOrderState({
                                            ...newOrderState,
                                            patientBirthdate: date,
                                        });
                                    }}
                                    dateFormat="d MMMM, yyyy"
                                    withPortal
                                    shouldCloseOnSelect={true}
                                    showMonthDropdown
                                    showYearDropdown
                                    onYearChange={date => {
                                        setNewOrderState({
                                            ...newOrderState,
                                            patientBirthdate: date,
                                        });
                                    }}
                                    onMonthChange={date => {
                                        setNewOrderState({
                                            ...newOrderState,
                                            patientBirthdate: date,
                                        });
                                    }}
                                    customInput={<CustomDatepickerInput />}
                                    dropdownMode="select"
                                    className={`text-center ${datePickerInputClassName}`}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label value="Versicherung" />
                        </div>
                        <div className="flex justify-around">
                            <div className="flex flex-col">
                                <div>
                                    <Checkbox
                                        checked={newOrderState.insurance === 'PRIVATE'}
                                        onChange={() => handleChange({ insurance: 'PRIVATE' })}
                                        name="insurance"
                                    />
                                    <Label onClick={() => handleChange({ insurance: 'PRIVATE' })}>
                                        {' '}
                                        privat
                                    </Label>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div>
                                    <Checkbox
                                        checked={newOrderState.insurance === 'PUBLIC'}
                                        onChange={() => handleChange({ insurance: 'PUBLIC' })}
                                        name="insurance"
                                    />
                                    <Label onClick={() => handleChange({ insurance: 'PUBLIC' })}>
                                        {' '}
                                        gesetzlich
                                    </Label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <DefaultAlerts
                    displaySuccessMsg={displaySuccessMsg}
                    displayErrMsg={displayErrMsg}
                    errMsg={errMsg}
                    successMsg={successMsg}
                />
            </div>
            <ContinueButtonGroup
                onClickBack={() => navigate(-1)}
                onClickForward={() => {
                    updateOrder();
                }}
            />
        </div>
    );
}
