import { forwardRef, useEffect, useRef, useState } from 'react';
import useAuth from '../hooks/useAuth';
import useErrMsg from '../hooks/use-errormsg';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import logout from '../utils/logout';
import { Button, Select, TextInput } from 'flowbite-react';
import DefaultAlerts from './default-alerts';
import ContinueButtonGroup from './continue-bgroup';
import { PlusCircleIcon, ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import useOrder from '../hooks/use-order';
import useUser from '../hooks/use-user';
//used for the datepicker
registerLocale('de', de);

const zweckSelectOptions = [
    'Abformung',
    'Löffelabformung',
    'Einprobe',
    'Gerüst-Einprobe',
    'Ästhetik-Einprobe',
    'Abutment-Einprobe',
    'Einsetzen',
    'Farbnahme mit Techniker',
    'siehe Notiz',
];
export const datePickerClassName = 'flex g-4 w-full justify-around';
export const datePickerInputClassName =
    'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500';

export const CustomDatepickerInput = forwardRef(({ value, onClick }, ref) => (
    <Button
        className="bg-gray-300 hover:bg-sky-700 w-full font-bold text-black"
        gradientDuoTone="cyanToBlue"
        outline="cyanToBlue"
        onClick={onClick}
        ref={ref}
    >
        {value}
    </Button>
));

export default function AppointmentsView() {
    const { activeOrder, setActiveOrder } = useOrder();
    const orderId = activeOrder.orderId;
    const [displayErrMsg, setDisplayErrMsg] = useState(false);
    const [displaySuccessMsg, setDisplaySuccessMsg] = useState(false);
    const { errMsg, setErrMsg, successMsg, stdErrMsg } = useErrMsg();
    const { auth, setAuth } = useAuth();
    const { setUserState } = useUser();
    const navigate = useNavigate();
    const dateList = useRef({});
    const [childPickers, setChildPickers] = useState([]);
    function addAnotherDatePicker() {
        setChildPickers([...childPickers, childPickers.length]);
    }

    useEffect(() => {
        if (activeOrder.appointments?.length > 0) {
            setChildPickers([]);
            activeOrder.appointments.forEach((a, i) => {
                dateList.current[i] = {
                    date: new Date(Date.parse(a.date)),
                    description: a.description,
                    note: a.note,
                };
                setChildPickers(c => [...c, i]);
            });
        }
    }, [activeOrder]);

    async function updateOrder() {
        setErrMsg(stdErrMsg);
        if (childPickers.length < 1) {
            return navigate('/orderViewSingle');
        }
        try {
            let response;
            const keys = Object.keys(dateList.current);
            //do those requests synchronously to avoid version errors on save
            for (const key of keys) {
                response = await axios.post(
                    '/order/addAppointment',
                    {
                        orderId,
                        ...dateList.current[key],
                        date: dateList.current[key].date.getTime(),
                        key: key,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${auth?.token}`,
                        },
                    }
                );
            }
            // await Promise.all(
            //     keys.map(async key => {
            //         response = await axios.post(
            //             '/order/addAppointment',
            //             {
            //                 orderId,
            //                 ...dateList.current[key],
            //                 date: dateList.current[key].date.getTime(),
            //                 key: key,
            //             },
            //             {
            //                 headers: {
            //                     'Content-Type': 'application/json',
            //                     Authorization: `Bearer ${auth?.token}`,
            //                 },
            //             }
            //         );
            //     })
            // );
            if (response.status === 200) {
                const { _id, ...body } = response.data;
                setActiveOrder({ ...body, orderId: _id });
                setDisplaySuccessMsg(true);
                setErrMsg(stdErrMsg);
                return setTimeout(() => navigate('/orderViewSingle'), 500);
            }
        } catch (error) {
            // on failure: print error and display error message
            if (!error?.response) {
                setErrMsg('Server konnte nicht erreicht werden.');
            }
            if (error?.response?.status === 401) {
                setErrMsg(stdErrMsg);
                return logout(auth, setAuth, setUserState);
            }
            console.error(error);
            setDisplayErrMsg(true);
        }
    }

    function CustomTimeInput({ date, onChange }) {
        const CustomInput = forwardRef(({ value, onClick }, ref) => (
            <Button className="ml-2 bg-sky-700 hover:bg-sky-800 w-72" onClick={onClick} ref={ref}>
                {value}
            </Button>
        ));
        return (
            <div>
                <DatePicker
                    selected={date}
                    onChange={date => {
                        onChange(
                            date.toTimeString().split(':')[0] +
                                ':' +
                                date.toTimeString().split(':')[1]
                        );
                    }}
                    locale="de"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption=""
                    timeFormat="p"
                    dateFormat="HH:mm"
                    showPopperArrow={false}
                    popperModifiers={[
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 10],
                            },
                        },
                        {
                            name: 'preventOverflow',
                            options: {
                                rootBoundary: 'viewport',
                                tether: false,
                                altAxis: true,
                            },
                        },
                    ]}
                    className={datePickerInputClassName}
                    customInput={<CustomInput />}
                    shouldCloseOnSelect={true}
                />
            </div>
        );
    }

    function MyDatePicker({ id, dateList }) {
        //need to do declare state in here and use this weird dateList in
        //order to avoid recomposition and following closing of datepicker on date change
        //and set correct state to avoid clearance of input values when
        //another datepicker gets added
        const [myDate, setMyDate] = useState(dateList[id]?.date ?? new Date());
        const [selected, setSelected] = useState(
            dateList[id]?.description ?? zweckSelectOptions[0]
        );
        const [note, setNote] = useState(dateList[id]?.note ?? '');
        dateList[id] = { date: myDate, description: selected, note: note };
        return (
            <>
                <div className="w-1/3">
                    <div>
                        <Select
                            id="zweckSelect"
                            required
                            value={selected}
                            onChange={event => {
                                // const name = event.target.name;
                                const value =
                                    event.target.type === 'checkbox'
                                        ? event.target.checked
                                        : event.target.value;
                                setSelected(value);
                                dateList[id] = { ...dateList[id], description: value };
                            }}
                            name={`zweckSelect${id}`}
                        >
                            {zweckSelectOptions.map(option => (
                                <option key={option}>{option}</option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="w-1/3 ml-1 mr-1">
                    <DatePicker
                        locale="de"
                        selected={myDate}
                        onChange={date => {
                            setMyDate(date);
                            dateList[id] = { ...dateList[id], date: date };
                        }}
                        timeCaption="Uhrzeit"
                        dateFormat="d MMMM, yyyy HH:mm"
                        timeInputLabel="Uhrzeit: "
                        showTimeInput
                        customTimeInput={<CustomTimeInput />}
                        customInput={<CustomDatepickerInput />}
                        withPortal
                        portalId="datepicker-portal"
                        shouldCloseOnSelect={false}
                        className="w-full text-center overflow-y-scroll"
                        monthsShown={2}
                    />
                </div>
                <div className="w-1/3">
                    <TextInput
                        icon={ChatBubbleBottomCenterTextIcon}
                        value={note}
                        placeholder="optionale Notiz..."
                        onChange={event => {
                            const value =
                                event.target.type === 'checkbox'
                                    ? event.target.checked
                                    : event.target.value;
                            setNote(value);
                            dateList[id] = { ...dateList[id], note: value };
                        }}
                    />
                </div>
            </>
        );
    }

    return (
        <div>
            <div className="relative flex flex-col gap-4 items-center min-h-screen ">
                <h1>Terminplanung</h1>
                {childPickers.map(id => (
                    <div key={`div${id}`} className={datePickerClassName}>
                        <MyDatePicker id={id} key={id} dateList={dateList.current} />
                    </div>
                ))}
                <div className="mt-14">
                    <PlusCircleIcon
                        className="h-24 sm:h-24 text-blue-500 hover:cursor-pointer"
                        onClick={addAnotherDatePicker}
                    />
                </div>
                <div>
                    <DefaultAlerts
                        displaySuccessMsg={displaySuccessMsg}
                        displayErrMsg={displayErrMsg}
                        errMsg={errMsg}
                        successMsg={successMsg}
                    />
                </div>
                <ContinueButtonGroup
                    onClickBack={() => navigate(-1)}
                    onClickForward={() => {
                        updateOrder();
                    }}
                />
            </div>
        </div>
    );
}
